/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ShiftiePlainDate } from './ShiftiePlainDate';
import {
    ShiftiePlainDateFromJSON,
    ShiftiePlainDateFromJSONTyped,
    ShiftiePlainDateToJSON,
} from './ShiftiePlainDate';

/**
 * Personal/private details about an employee that shouldn't be visible to everyone
 * @export
 * @interface EmployeePersonalDetails
 */
export interface EmployeePersonalDetails {
    /**
     * 
     * @type {number}
     * @memberof EmployeePersonalDetails
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof EmployeePersonalDetails
     */
    employeeId: number;
    /**
     * 
     * @type {string}
     * @memberof EmployeePersonalDetails
     */
    address1: string | null;
    /**
     * 
     * @type {string}
     * @memberof EmployeePersonalDetails
     */
    address2: string | null;
    /**
     * 
     * @type {string}
     * @memberof EmployeePersonalDetails
     */
    city: string | null;
    /**
     * 
     * @type {string}
     * @memberof EmployeePersonalDetails
     */
    postalCode: string | null;
    /**
     * 
     * @type {string}
     * @memberof EmployeePersonalDetails
     */
    region: string | null;
    /**
     * 
     * @type {string}
     * @memberof EmployeePersonalDetails
     */
    country: string | null;
    /**
     * 
     * @type {ShiftiePlainDate}
     * @memberof EmployeePersonalDetails
     */
    dateOfBirth: ShiftiePlainDate | null;
    /**
     * 
     * @type {string}
     * @memberof EmployeePersonalDetails
     */
    gender: string | null;
    /**
     * 
     * @type {string}
     * @memberof EmployeePersonalDetails
     */
    nationality: string | null;
    /**
     * 
     * @type {string}
     * @memberof EmployeePersonalDetails
     */
    ethnicity: string | null;
    /**
     * 
     * @type {string}
     * @memberof EmployeePersonalDetails
     */
    maritalStatus: string | null;
    /**
     * 
     * @type {string}
     * @memberof EmployeePersonalDetails
     */
    nationalInsuranceNumber: string | null;
    /**
     * 
     * @type {string}
     * @memberof EmployeePersonalDetails
     */
    drivingLicenceNumber: string | null;
    /**
     * 
     * @type {string}
     * @memberof EmployeePersonalDetails
     */
    passportNumber: string | null;
    /**
     * 
     * @type {string}
     * @memberof EmployeePersonalDetails
     */
    notes: string | null;
    /**
     * 
     * @type {string}
     * @memberof EmployeePersonalDetails
     */
    medicalDetails?: string;
}

/**
 * Check if a given object implements the EmployeePersonalDetails interface.
 */
export function instanceOfEmployeePersonalDetails(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('employeeId' in value)) return false;
    if (!('address1' in value)) return false;
    if (!('address2' in value)) return false;
    if (!('city' in value)) return false;
    if (!('postalCode' in value)) return false;
    if (!('region' in value)) return false;
    if (!('country' in value)) return false;
    if (!('dateOfBirth' in value)) return false;
    if (!('gender' in value)) return false;
    if (!('nationality' in value)) return false;
    if (!('ethnicity' in value)) return false;
    if (!('maritalStatus' in value)) return false;
    if (!('nationalInsuranceNumber' in value)) return false;
    if (!('drivingLicenceNumber' in value)) return false;
    if (!('passportNumber' in value)) return false;
    if (!('notes' in value)) return false;
    return true;
}

export function EmployeePersonalDetailsFromJSON(json: any): EmployeePersonalDetails {
    return EmployeePersonalDetailsFromJSONTyped(json, false);
}

export function EmployeePersonalDetailsFromJSONTyped(json: any, ignoreDiscriminator: boolean): EmployeePersonalDetails {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'employeeId': json['employeeId'],
        'address1': json['address1'],
        'address2': json['address2'],
        'city': json['city'],
        'postalCode': json['postalCode'],
        'region': json['region'],
        'country': json['country'],
        'dateOfBirth': ShiftiePlainDateFromJSON(json['dateOfBirth']),
        'gender': json['gender'],
        'nationality': json['nationality'],
        'ethnicity': json['ethnicity'],
        'maritalStatus': json['maritalStatus'],
        'nationalInsuranceNumber': json['nationalInsuranceNumber'],
        'drivingLicenceNumber': json['drivingLicenceNumber'],
        'passportNumber': json['passportNumber'],
        'notes': json['notes'],
        'medicalDetails': json['medicalDetails'] == null ? undefined : json['medicalDetails'],
    };
}

export function EmployeePersonalDetailsToJSON(value?: EmployeePersonalDetails | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'employeeId': value['employeeId'],
        'address1': value['address1'],
        'address2': value['address2'],
        'city': value['city'],
        'postalCode': value['postalCode'],
        'region': value['region'],
        'country': value['country'],
        'dateOfBirth': ShiftiePlainDateToJSON(value['dateOfBirth']),
        'gender': value['gender'],
        'nationality': value['nationality'],
        'ethnicity': value['ethnicity'],
        'maritalStatus': value['maritalStatus'],
        'nationalInsuranceNumber': value['nationalInsuranceNumber'],
        'drivingLicenceNumber': value['drivingLicenceNumber'],
        'passportNumber': value['passportNumber'],
        'notes': value['notes'],
        'medicalDetails': value['medicalDetails'],
    };
}

