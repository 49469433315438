/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  BulkUpdateEmployeesData,
  ConfirmEmployeeData,
  ConfirmEmployeeResponse,
  CreateEmployeeData,
  CreateEmployeeImportData,
  CreateEmployeeImportResponse,
  CreateEmployeeResponse,
  ListAvailableEmployeesResponse,
  ListEmployeeClockingProfilesResponse,
  ListEmployeeNotificationChannelsResponse,
  ListEmployeePayDetailResponse,
  ListEmployeePersonalDetailsResponse,
  ListEmployeesBelongingToUserResponse,
  ListEmployeesResponse,
  ListSubordinatesResponse,
  RequestPhoneVerificationData,
  SendEmployeeInviteData,
  SendEmployeeInvitesData,
  ShowCurrentEmployeeResponse,
  ShowEmployeeClockingProfileResponse,
  ShowEmployeeOnboardingResponse,
  ShowEmployeePersonalDetailResponse,
  ShowEmployeeResponse,
  UpdateEmployeeData,
  UpdateEmployeeNotificationChannelData,
  UpdateEmployeeNotificationChannelResponse,
  UpdateEmployeeOnboardingData,
  UpdateEmployeeOnboardingResponse,
  UpdateEmployeePayData,
  UpdateEmployeePayDetailResponse,
  UpdateEmployeePersonalDetailsData,
  UpdateEmployeePersonalDetailsResponse,
  UpdateEmployeeResponse,
  VerifyPhoneNumberData,
} from '../models/index';
import {
    BulkUpdateEmployeesDataFromJSON,
    BulkUpdateEmployeesDataToJSON,
    ConfirmEmployeeDataFromJSON,
    ConfirmEmployeeDataToJSON,
    ConfirmEmployeeResponseFromJSON,
    ConfirmEmployeeResponseToJSON,
    CreateEmployeeDataFromJSON,
    CreateEmployeeDataToJSON,
    CreateEmployeeImportDataFromJSON,
    CreateEmployeeImportDataToJSON,
    CreateEmployeeImportResponseFromJSON,
    CreateEmployeeImportResponseToJSON,
    CreateEmployeeResponseFromJSON,
    CreateEmployeeResponseToJSON,
    ListAvailableEmployeesResponseFromJSON,
    ListAvailableEmployeesResponseToJSON,
    ListEmployeeClockingProfilesResponseFromJSON,
    ListEmployeeClockingProfilesResponseToJSON,
    ListEmployeeNotificationChannelsResponseFromJSON,
    ListEmployeeNotificationChannelsResponseToJSON,
    ListEmployeePayDetailResponseFromJSON,
    ListEmployeePayDetailResponseToJSON,
    ListEmployeePersonalDetailsResponseFromJSON,
    ListEmployeePersonalDetailsResponseToJSON,
    ListEmployeesBelongingToUserResponseFromJSON,
    ListEmployeesBelongingToUserResponseToJSON,
    ListEmployeesResponseFromJSON,
    ListEmployeesResponseToJSON,
    ListSubordinatesResponseFromJSON,
    ListSubordinatesResponseToJSON,
    RequestPhoneVerificationDataFromJSON,
    RequestPhoneVerificationDataToJSON,
    SendEmployeeInviteDataFromJSON,
    SendEmployeeInviteDataToJSON,
    SendEmployeeInvitesDataFromJSON,
    SendEmployeeInvitesDataToJSON,
    ShowCurrentEmployeeResponseFromJSON,
    ShowCurrentEmployeeResponseToJSON,
    ShowEmployeeClockingProfileResponseFromJSON,
    ShowEmployeeClockingProfileResponseToJSON,
    ShowEmployeeOnboardingResponseFromJSON,
    ShowEmployeeOnboardingResponseToJSON,
    ShowEmployeePersonalDetailResponseFromJSON,
    ShowEmployeePersonalDetailResponseToJSON,
    ShowEmployeeResponseFromJSON,
    ShowEmployeeResponseToJSON,
    UpdateEmployeeDataFromJSON,
    UpdateEmployeeDataToJSON,
    UpdateEmployeeNotificationChannelDataFromJSON,
    UpdateEmployeeNotificationChannelDataToJSON,
    UpdateEmployeeNotificationChannelResponseFromJSON,
    UpdateEmployeeNotificationChannelResponseToJSON,
    UpdateEmployeeOnboardingDataFromJSON,
    UpdateEmployeeOnboardingDataToJSON,
    UpdateEmployeeOnboardingResponseFromJSON,
    UpdateEmployeeOnboardingResponseToJSON,
    UpdateEmployeePayDataFromJSON,
    UpdateEmployeePayDataToJSON,
    UpdateEmployeePayDetailResponseFromJSON,
    UpdateEmployeePayDetailResponseToJSON,
    UpdateEmployeePersonalDetailsDataFromJSON,
    UpdateEmployeePersonalDetailsDataToJSON,
    UpdateEmployeePersonalDetailsResponseFromJSON,
    UpdateEmployeePersonalDetailsResponseToJSON,
    UpdateEmployeeResponseFromJSON,
    UpdateEmployeeResponseToJSON,
    VerifyPhoneNumberDataFromJSON,
    VerifyPhoneNumberDataToJSON,
} from '../models/index';

export interface BulkUpdateEmployeesRequest {
    where?: { [key: string]: object; };
    bulkUpdateEmployeesData?: BulkUpdateEmployeesData;
}

export interface ConfirmEmployeeRequest {
    confirmEmployeeData?: ConfirmEmployeeData;
}

export interface CreateEmployeeRequest {
    _with?: Array<string>;
    createEmployeeData?: CreateEmployeeData;
}

export interface CreateEmployeeImportRequest {
    createEmployeeImportData?: CreateEmployeeImportData;
}

export interface DeleteEmployeeRequest {
    id: number;
}

export interface InviteEmployeeRequest {
    id: number;
    sendEmployeeInviteData?: SendEmployeeInviteData;
}

export interface ListAvailableEmployeesRequest {
    startsAt: Date;
    endsAt: Date;
    jobRoleId?: number;
    locationId?: number;
    scheduleId?: number;
    applyWorkPatternFiltering?: boolean;
    includeUnpublishedShifts?: boolean;
    _with?: Array<string>;
    limit?: number;
    orderBy?: Array<string>;
    page?: number;
    perPage?: number;
    where?: { [key: string]: object; };
}

export interface ListEmployeeClockingProfilesRequest {
    limit?: number;
    orderBy?: Array<string>;
    page?: number;
    perPage?: number;
    where?: { [key: string]: object; };
}

export interface ListEmployeePayDetailRequest {
    limit?: number;
    orderBy?: Array<string>;
    page?: number;
    perPage?: number;
    where?: { [key: string]: object; };
}

export interface ListEmployeePersonalDetailsRequest {
    limit?: number;
    orderBy?: Array<string>;
    page?: number;
    perPage?: number;
    q?: string;
    where?: { [key: string]: object; };
    _with?: Array<string>;
}

export interface ListEmployeesRequest {
    limit?: number;
    orderBy?: Array<string>;
    page?: number;
    perPage?: number;
    q?: string;
    where?: { [key: string]: object; };
    _with?: Array<string>;
}

export interface ListEmployeesBelongingToUserRequest {
    page?: number;
    perPage?: number;
    _with?: Array<string>;
}

export interface ListSubordinatesRequest {
    limit?: number;
    page?: number;
    perPage?: number;
}

export interface RequestPhoneVerificationRequest {
    requestPhoneVerificationData?: RequestPhoneVerificationData;
}

export interface SendEmployeeInvitesRequest {
    sendEmployeeInvitesData?: SendEmployeeInvitesData;
}

export interface ShowCurrentEmployeeRequest {
    _with?: Array<string>;
}

export interface ShowEmployeeRequest {
    id: number;
    _with?: Array<string>;
}

export interface ShowEmployeeClockingProfileRequest {
    id: number;
}

export interface ShowEmployeePersonalDetailRequest {
    id: number;
}

export interface UpdateEmployeeRequest {
    id: number;
    _with?: Array<string>;
    updateEmployeeData?: UpdateEmployeeData;
}

export interface UpdateEmployeeNotificationChannelRequest {
    group: UpdateEmployeeNotificationChannelGroupEnum;
    channel: UpdateEmployeeNotificationChannelChannelEnum;
    updateEmployeeNotificationChannelData?: UpdateEmployeeNotificationChannelData;
}

export interface UpdateEmployeeOnboardingRequest {
    updateEmployeeOnboardingData?: UpdateEmployeeOnboardingData;
}

export interface UpdateEmployeePayDetailRequest {
    id: string;
    updateEmployeePayData?: UpdateEmployeePayData;
}

export interface UpdateEmployeePersonalDetailsRequest {
    id: number;
    updateEmployeePersonalDetailsData?: UpdateEmployeePersonalDetailsData;
}

export interface VerifyPhoneNumberRequest {
    verifyPhoneNumberData?: VerifyPhoneNumberData;
}

/**
 * 
 */
export class EmployeeApi extends runtime.BaseAPI {

    /**
     * Bulk update a collection of employees.
     * Bulk Update Employees
     */
    async bulkUpdateEmployeesRaw(requestParameters: BulkUpdateEmployeesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters['where'] != null) {
            queryParameters['where'] = requestParameters['where'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.employee.write"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/employees`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: BulkUpdateEmployeesDataToJSON(requestParameters['bulkUpdateEmployeesData']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Bulk update a collection of employees.
     * Bulk Update Employees
     */
    async bulkUpdateEmployees(requestParameters: BulkUpdateEmployeesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.bulkUpdateEmployeesRaw(requestParameters, initOverrides);
    }

    /**
     * Confirm the employment of a newly invited employee.
     * Confirm Employee
     */
    async confirmEmployeeRaw(requestParameters: ConfirmEmployeeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ConfirmEmployeeResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/employees/confirm`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ConfirmEmployeeDataToJSON(requestParameters['confirmEmployeeData']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ConfirmEmployeeResponseFromJSON(jsonValue));
    }

    /**
     * Confirm the employment of a newly invited employee.
     * Confirm Employee
     */
    async confirmEmployee(requestParameters: ConfirmEmployeeRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ConfirmEmployeeResponse> {
        const response = await this.confirmEmployeeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a new employee
     * Create Employee
     */
    async createEmployeeRaw(requestParameters: CreateEmployeeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateEmployeeResponse>> {
        const queryParameters: any = {};

        if (requestParameters['_with'] != null) {
            queryParameters['with'] = requestParameters['_with'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.employee.write"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/employees`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateEmployeeDataToJSON(requestParameters['createEmployeeData']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateEmployeeResponseFromJSON(jsonValue));
    }

    /**
     * Create a new employee
     * Create Employee
     */
    async createEmployee(requestParameters: CreateEmployeeRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateEmployeeResponse> {
        const response = await this.createEmployeeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a new employee import, using an existing uploaded CSV.
     * Create Employee Import
     */
    async createEmployeeImportRaw(requestParameters: CreateEmployeeImportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateEmployeeImportResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.employee-import.write"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/employee-import`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateEmployeeImportDataToJSON(requestParameters['createEmployeeImportData']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateEmployeeImportResponseFromJSON(jsonValue));
    }

    /**
     * Create a new employee import, using an existing uploaded CSV.
     * Create Employee Import
     */
    async createEmployeeImport(requestParameters: CreateEmployeeImportRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateEmployeeImportResponse> {
        const response = await this.createEmployeeImportRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mark an employee as having left, and anonymise their personal details
     * Delete Employee
     */
    async deleteEmployeeRaw(requestParameters: DeleteEmployeeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling deleteEmployee().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.employee.write"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/employees/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Mark an employee as having left, and anonymise their personal details
     * Delete Employee
     */
    async deleteEmployee(requestParameters: DeleteEmployeeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteEmployeeRaw(requestParameters, initOverrides);
    }

    /**
     * Send an invite to a single employee and optionally update their user\'s email
     * Invite a single employee
     */
    async inviteEmployeeRaw(requestParameters: InviteEmployeeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling inviteEmployee().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.employee.write"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/employees/invite/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SendEmployeeInviteDataToJSON(requestParameters['sendEmployeeInviteData']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Send an invite to a single employee and optionally update their user\'s email
     * Invite a single employee
     */
    async inviteEmployee(requestParameters: InviteEmployeeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.inviteEmployeeRaw(requestParameters, initOverrides);
    }

    /**
     * List available employees between 2 datetimes
     * List Available Employees
     */
    async listAvailableEmployeesRaw(requestParameters: ListAvailableEmployeesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListAvailableEmployeesResponse>> {
        if (requestParameters['startsAt'] == null) {
            throw new runtime.RequiredError(
                'startsAt',
                'Required parameter "startsAt" was null or undefined when calling listAvailableEmployees().'
            );
        }

        if (requestParameters['endsAt'] == null) {
            throw new runtime.RequiredError(
                'endsAt',
                'Required parameter "endsAt" was null or undefined when calling listAvailableEmployees().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['startsAt'] != null) {
            queryParameters['startsAt'] = (requestParameters['startsAt'] as any).toISOString();
        }

        if (requestParameters['endsAt'] != null) {
            queryParameters['endsAt'] = (requestParameters['endsAt'] as any).toISOString();
        }

        if (requestParameters['jobRoleId'] != null) {
            queryParameters['jobRoleId'] = requestParameters['jobRoleId'];
        }

        if (requestParameters['locationId'] != null) {
            queryParameters['locationId'] = requestParameters['locationId'];
        }

        if (requestParameters['scheduleId'] != null) {
            queryParameters['scheduleId'] = requestParameters['scheduleId'];
        }

        if (requestParameters['applyWorkPatternFiltering'] != null) {
            queryParameters['applyWorkPatternFiltering'] = requestParameters['applyWorkPatternFiltering'];
        }

        if (requestParameters['includeUnpublishedShifts'] != null) {
            queryParameters['includeUnpublishedShifts'] = requestParameters['includeUnpublishedShifts'];
        }

        if (requestParameters['_with'] != null) {
            queryParameters['with'] = requestParameters['_with'];
        }

        if (requestParameters['limit'] != null) {
            queryParameters['limit'] = requestParameters['limit'];
        }

        if (requestParameters['orderBy'] != null) {
            queryParameters['orderBy'] = requestParameters['orderBy'];
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['perPage'] != null) {
            queryParameters['perPage'] = requestParameters['perPage'];
        }

        if (requestParameters['where'] != null) {
            queryParameters['where'] = requestParameters['where'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.employee.read"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/available-employees`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListAvailableEmployeesResponseFromJSON(jsonValue));
    }

    /**
     * List available employees between 2 datetimes
     * List Available Employees
     */
    async listAvailableEmployees(requestParameters: ListAvailableEmployeesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListAvailableEmployeesResponse> {
        const response = await this.listAvailableEmployeesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     * List Employee Clocking Profiles
     */
    async listEmployeeClockingProfilesRaw(requestParameters: ListEmployeeClockingProfilesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListEmployeeClockingProfilesResponse>> {
        const queryParameters: any = {};

        if (requestParameters['limit'] != null) {
            queryParameters['limit'] = requestParameters['limit'];
        }

        if (requestParameters['orderBy'] != null) {
            queryParameters['orderBy'] = requestParameters['orderBy'];
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['perPage'] != null) {
            queryParameters['perPage'] = requestParameters['perPage'];
        }

        if (requestParameters['where'] != null) {
            queryParameters['where'] = requestParameters['where'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.employee-clocking-profile.read"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/employee-clocking-profiles`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListEmployeeClockingProfilesResponseFromJSON(jsonValue));
    }

    /**
     * 
     * List Employee Clocking Profiles
     */
    async listEmployeeClockingProfiles(requestParameters: ListEmployeeClockingProfilesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListEmployeeClockingProfilesResponse> {
        const response = await this.listEmployeeClockingProfilesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List your enabled notification channels.
     * List Employee Notification Channels
     */
    async listEmployeeNotificationChannelsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListEmployeeNotificationChannelsResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.employee-notification-channel.read"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/employee/notification-channels`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListEmployeeNotificationChannelsResponseFromJSON(jsonValue));
    }

    /**
     * List your enabled notification channels.
     * List Employee Notification Channels
     */
    async listEmployeeNotificationChannels(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListEmployeeNotificationChannelsResponse> {
        const response = await this.listEmployeeNotificationChannelsRaw(initOverrides);
        return await response.value();
    }

    /**
     * List Employee Pay
     */
    async listEmployeePayDetailRaw(requestParameters: ListEmployeePayDetailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListEmployeePayDetailResponse>> {
        const queryParameters: any = {};

        if (requestParameters['limit'] != null) {
            queryParameters['limit'] = requestParameters['limit'];
        }

        if (requestParameters['orderBy'] != null) {
            queryParameters['orderBy'] = requestParameters['orderBy'];
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['perPage'] != null) {
            queryParameters['perPage'] = requestParameters['perPage'];
        }

        if (requestParameters['where'] != null) {
            queryParameters['where'] = requestParameters['where'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.employee-pay.read"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/employee-pay`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListEmployeePayDetailResponseFromJSON(jsonValue));
    }

    /**
     * List Employee Pay
     */
    async listEmployeePayDetail(requestParameters: ListEmployeePayDetailRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListEmployeePayDetailResponse> {
        const response = await this.listEmployeePayDetailRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List or filter all personal details
     * List Employee Personal Details
     */
    async listEmployeePersonalDetailsRaw(requestParameters: ListEmployeePersonalDetailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListEmployeePersonalDetailsResponse>> {
        const queryParameters: any = {};

        if (requestParameters['limit'] != null) {
            queryParameters['limit'] = requestParameters['limit'];
        }

        if (requestParameters['orderBy'] != null) {
            queryParameters['orderBy'] = requestParameters['orderBy'];
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['perPage'] != null) {
            queryParameters['perPage'] = requestParameters['perPage'];
        }

        if (requestParameters['q'] != null) {
            queryParameters['q'] = requestParameters['q'];
        }

        if (requestParameters['where'] != null) {
            queryParameters['where'] = requestParameters['where'];
        }

        if (requestParameters['_with'] != null) {
            queryParameters['with'] = requestParameters['_with'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.employee-personal-details.read"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/employee-personal-details`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListEmployeePersonalDetailsResponseFromJSON(jsonValue));
    }

    /**
     * List or filter all personal details
     * List Employee Personal Details
     */
    async listEmployeePersonalDetails(requestParameters: ListEmployeePersonalDetailsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListEmployeePersonalDetailsResponse> {
        const response = await this.listEmployeePersonalDetailsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List or filter all employees
     * List Employees
     */
    async listEmployeesRaw(requestParameters: ListEmployeesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListEmployeesResponse>> {
        const queryParameters: any = {};

        if (requestParameters['limit'] != null) {
            queryParameters['limit'] = requestParameters['limit'];
        }

        if (requestParameters['orderBy'] != null) {
            queryParameters['orderBy'] = requestParameters['orderBy'];
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['perPage'] != null) {
            queryParameters['perPage'] = requestParameters['perPage'];
        }

        if (requestParameters['q'] != null) {
            queryParameters['q'] = requestParameters['q'];
        }

        if (requestParameters['where'] != null) {
            queryParameters['where'] = requestParameters['where'];
        }

        if (requestParameters['_with'] != null) {
            queryParameters['with'] = requestParameters['_with'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.employee.read"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/employees`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListEmployeesResponseFromJSON(jsonValue));
    }

    /**
     * List or filter all employees
     * List Employees
     */
    async listEmployees(requestParameters: ListEmployeesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListEmployeesResponse> {
        const response = await this.listEmployeesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Employees that the current user relates to
     * My Employees
     */
    async listEmployeesBelongingToUserRaw(requestParameters: ListEmployeesBelongingToUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListEmployeesBelongingToUserResponse>> {
        const queryParameters: any = {};

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['perPage'] != null) {
            queryParameters['perPage'] = requestParameters['perPage'];
        }

        if (requestParameters['_with'] != null) {
            queryParameters['with'] = requestParameters['_with'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.employee.read"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/employees/mine`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListEmployeesBelongingToUserResponseFromJSON(jsonValue));
    }

    /**
     * Employees that the current user relates to
     * My Employees
     */
    async listEmployeesBelongingToUser(requestParameters: ListEmployeesBelongingToUserRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListEmployeesBelongingToUserResponse> {
        const response = await this.listEmployeesBelongingToUserRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * All direct and indirect subordinates of the current employee. If the current employee is the primary contact for the company, this also includes all staff who don\'t have a line manager
     * List Subordinates
     */
    async listSubordinatesRaw(requestParameters: ListSubordinatesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListSubordinatesResponse>> {
        const queryParameters: any = {};

        if (requestParameters['limit'] != null) {
            queryParameters['limit'] = requestParameters['limit'];
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['perPage'] != null) {
            queryParameters['perPage'] = requestParameters['perPage'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.employee.read"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/employees/subordinates`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListSubordinatesResponseFromJSON(jsonValue));
    }

    /**
     * All direct and indirect subordinates of the current employee. If the current employee is the primary contact for the company, this also includes all staff who don\'t have a line manager
     * List Subordinates
     */
    async listSubordinates(requestParameters: ListSubordinatesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListSubordinatesResponse> {
        const response = await this.listSubordinatesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Request a new 6 digit otp to be sent to your registered phone number.
     * Request Phone Verification
     */
    async requestPhoneVerificationRaw(requestParameters: RequestPhoneVerificationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.employee.write"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/employees/send-phone-verification`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RequestPhoneVerificationDataToJSON(requestParameters['requestPhoneVerificationData']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Request a new 6 digit otp to be sent to your registered phone number.
     * Request Phone Verification
     */
    async requestPhoneVerification(requestParameters: RequestPhoneVerificationRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.requestPhoneVerificationRaw(requestParameters, initOverrides);
    }

    /**
     * Send invites to either a selection or all employees with unconfirmed user accounts
     * Invite multiple employees
     */
    async sendEmployeeInvitesRaw(requestParameters: SendEmployeeInvitesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.employee.write"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/employees/invite`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SendEmployeeInvitesDataToJSON(requestParameters['sendEmployeeInvitesData']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Send invites to either a selection or all employees with unconfirmed user accounts
     * Invite multiple employees
     */
    async sendEmployeeInvites(requestParameters: SendEmployeeInvitesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.sendEmployeeInvitesRaw(requestParameters, initOverrides);
    }

    /**
     * Shows the current employee for the user in the scope of a company
     * Show Current Employee
     */
    async showCurrentEmployeeRaw(requestParameters: ShowCurrentEmployeeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ShowCurrentEmployeeResponse>> {
        const queryParameters: any = {};

        if (requestParameters['_with'] != null) {
            queryParameters['with'] = requestParameters['_with'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.employee.read"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/employee/me`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ShowCurrentEmployeeResponseFromJSON(jsonValue));
    }

    /**
     * Shows the current employee for the user in the scope of a company
     * Show Current Employee
     */
    async showCurrentEmployee(requestParameters: ShowCurrentEmployeeRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ShowCurrentEmployeeResponse> {
        const response = await this.showCurrentEmployeeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Show details of an existing employee
     * Show Employee
     */
    async showEmployeeRaw(requestParameters: ShowEmployeeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ShowEmployeeResponse>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling showEmployee().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['_with'] != null) {
            queryParameters['with'] = requestParameters['_with'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.employee.read"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/employees/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ShowEmployeeResponseFromJSON(jsonValue));
    }

    /**
     * Show details of an existing employee
     * Show Employee
     */
    async showEmployee(requestParameters: ShowEmployeeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ShowEmployeeResponse> {
        const response = await this.showEmployeeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Show Employee Clocking Profile
     */
    async showEmployeeClockingProfileRaw(requestParameters: ShowEmployeeClockingProfileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ShowEmployeeClockingProfileResponse>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling showEmployeeClockingProfile().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.employee-clocking-profile.read"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/employee-clocking-profiles/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ShowEmployeeClockingProfileResponseFromJSON(jsonValue));
    }

    /**
     * Show Employee Clocking Profile
     */
    async showEmployeeClockingProfile(requestParameters: ShowEmployeeClockingProfileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ShowEmployeeClockingProfileResponse> {
        const response = await this.showEmployeeClockingProfileRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Show Employee Onboarding
     */
    async showEmployeeOnboardingRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ShowEmployeeOnboardingResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.employee-onboarding.read"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/employee/onboarding`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ShowEmployeeOnboardingResponseFromJSON(jsonValue));
    }

    /**
     * Show Employee Onboarding
     */
    async showEmployeeOnboarding(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ShowEmployeeOnboardingResponse> {
        const response = await this.showEmployeeOnboardingRaw(initOverrides);
        return await response.value();
    }

    /**
     * Show personal details of a single employee
     * Show Employee Personal Details
     */
    async showEmployeePersonalDetailRaw(requestParameters: ShowEmployeePersonalDetailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ShowEmployeePersonalDetailResponse>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling showEmployeePersonalDetail().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.employee-personal-details.read"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/employee-personal-details/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ShowEmployeePersonalDetailResponseFromJSON(jsonValue));
    }

    /**
     * Show personal details of a single employee
     * Show Employee Personal Details
     */
    async showEmployeePersonalDetail(requestParameters: ShowEmployeePersonalDetailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ShowEmployeePersonalDetailResponse> {
        const response = await this.showEmployeePersonalDetailRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update the details of an existing Employee
     * Update Employee
     */
    async updateEmployeeRaw(requestParameters: UpdateEmployeeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UpdateEmployeeResponse>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling updateEmployee().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['_with'] != null) {
            queryParameters['with'] = requestParameters['_with'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.employee.write"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/employees/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateEmployeeDataToJSON(requestParameters['updateEmployeeData']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UpdateEmployeeResponseFromJSON(jsonValue));
    }

    /**
     * Update the details of an existing Employee
     * Update Employee
     */
    async updateEmployee(requestParameters: UpdateEmployeeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UpdateEmployeeResponse> {
        const response = await this.updateEmployeeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Toggle a notification channel for a given group.
     * Update Employee Notification Channel
     */
    async updateEmployeeNotificationChannelRaw(requestParameters: UpdateEmployeeNotificationChannelRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UpdateEmployeeNotificationChannelResponse>> {
        if (requestParameters['group'] == null) {
            throw new runtime.RequiredError(
                'group',
                'Required parameter "group" was null or undefined when calling updateEmployeeNotificationChannel().'
            );
        }

        if (requestParameters['channel'] == null) {
            throw new runtime.RequiredError(
                'channel',
                'Required parameter "channel" was null or undefined when calling updateEmployeeNotificationChannel().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.employee-notification-channel.write"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/employee/notification-channels/{group}/{channel}`.replace(`{${"group"}}`, encodeURIComponent(String(requestParameters['group']))).replace(`{${"channel"}}`, encodeURIComponent(String(requestParameters['channel']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateEmployeeNotificationChannelDataToJSON(requestParameters['updateEmployeeNotificationChannelData']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UpdateEmployeeNotificationChannelResponseFromJSON(jsonValue));
    }

    /**
     * Toggle a notification channel for a given group.
     * Update Employee Notification Channel
     */
    async updateEmployeeNotificationChannel(requestParameters: UpdateEmployeeNotificationChannelRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UpdateEmployeeNotificationChannelResponse> {
        const response = await this.updateEmployeeNotificationChannelRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update Employee Onboarding
     */
    async updateEmployeeOnboardingRaw(requestParameters: UpdateEmployeeOnboardingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UpdateEmployeeOnboardingResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.employee-onboarding.write"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/employee/onboarding`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateEmployeeOnboardingDataToJSON(requestParameters['updateEmployeeOnboardingData']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UpdateEmployeeOnboardingResponseFromJSON(jsonValue));
    }

    /**
     * Update Employee Onboarding
     */
    async updateEmployeeOnboarding(requestParameters: UpdateEmployeeOnboardingRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UpdateEmployeeOnboardingResponse> {
        const response = await this.updateEmployeeOnboardingRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     * Update Employee Pay
     */
    async updateEmployeePayDetailRaw(requestParameters: UpdateEmployeePayDetailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UpdateEmployeePayDetailResponse>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling updateEmployeePayDetail().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.employee-pay.write"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/employee-pay/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateEmployeePayDataToJSON(requestParameters['updateEmployeePayData']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UpdateEmployeePayDetailResponseFromJSON(jsonValue));
    }

    /**
     * 
     * Update Employee Pay
     */
    async updateEmployeePayDetail(requestParameters: UpdateEmployeePayDetailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UpdateEmployeePayDetailResponse> {
        const response = await this.updateEmployeePayDetailRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update an employee\'s personal details
     * Update Employee Personal Details
     */
    async updateEmployeePersonalDetailsRaw(requestParameters: UpdateEmployeePersonalDetailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UpdateEmployeePersonalDetailsResponse>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling updateEmployeePersonalDetails().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.employee-personal-details.write"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/employee-personal-details/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateEmployeePersonalDetailsDataToJSON(requestParameters['updateEmployeePersonalDetailsData']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UpdateEmployeePersonalDetailsResponseFromJSON(jsonValue));
    }

    /**
     * Update an employee\'s personal details
     * Update Employee Personal Details
     */
    async updateEmployeePersonalDetails(requestParameters: UpdateEmployeePersonalDetailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UpdateEmployeePersonalDetailsResponse> {
        const response = await this.updateEmployeePersonalDetailsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Verify your registered phone number using a 6-digit otp
     * Verify Phone Number
     */
    async verifyPhoneNumberRaw(requestParameters: VerifyPhoneNumberRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.employee.write"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/employees/verify-phone-number`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: VerifyPhoneNumberDataToJSON(requestParameters['verifyPhoneNumberData']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Verify your registered phone number using a 6-digit otp
     * Verify Phone Number
     */
    async verifyPhoneNumber(requestParameters: VerifyPhoneNumberRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.verifyPhoneNumberRaw(requestParameters, initOverrides);
    }

}

/**
  * @export
  * @enum {string}
  */
export enum UpdateEmployeeNotificationChannelGroupEnum {
    Billing = 'Billing',
    Document = 'Document',
    Leave = 'Leave',
    Shift = 'Shift',
    Absence = 'Absence',
    Timesheet = 'Timesheet'
}
/**
  * @export
  * @enum {string}
  */
export enum UpdateEmployeeNotificationChannelChannelEnum {
    Email = 'Email',
    Push = 'Push',
    Sms = 'Sms'
}
