/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateShiftAreaSessionData
 */
export interface UpdateShiftAreaSessionData {
    /**
     * 
     * @type {number}
     * @memberof UpdateShiftAreaSessionData
     */
    shiftId?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateShiftAreaSessionData
     */
    shiftAreaId?: number;
    /**
     * 
     * @type {Date}
     * @memberof UpdateShiftAreaSessionData
     */
    startTime?: Date;
    /**
     * 
     * @type {Date}
     * @memberof UpdateShiftAreaSessionData
     */
    endTime?: Date;
}

/**
 * Check if a given object implements the UpdateShiftAreaSessionData interface.
 */
export function instanceOfUpdateShiftAreaSessionData(value: object): boolean {
    return true;
}

export function UpdateShiftAreaSessionDataFromJSON(json: any): UpdateShiftAreaSessionData {
    return UpdateShiftAreaSessionDataFromJSONTyped(json, false);
}

export function UpdateShiftAreaSessionDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateShiftAreaSessionData {
    if (json == null) {
        return json;
    }
    return {
        
        'shiftId': json['shiftId'] == null ? undefined : json['shiftId'],
        'shiftAreaId': json['shiftAreaId'] == null ? undefined : json['shiftAreaId'],
        'startTime': json['startTime'] == null ? undefined : (new Date(json['startTime'])),
        'endTime': json['endTime'] == null ? undefined : (new Date(json['endTime'])),
    };
}

export function UpdateShiftAreaSessionDataToJSON(value?: UpdateShiftAreaSessionData | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'shiftId': value['shiftId'],
        'shiftAreaId': value['shiftAreaId'],
        'startTime': value['startTime'] == null ? undefined : ((value['startTime']).toISOString()),
        'endTime': value['endTime'] == null ? undefined : ((value['endTime']).toISOString()),
    };
}

