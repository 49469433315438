/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ShiftAreaSession } from './ShiftAreaSession';
import {
    ShiftAreaSessionFromJSON,
    ShiftAreaSessionFromJSONTyped,
    ShiftAreaSessionToJSON,
} from './ShiftAreaSession';

/**
 * 
 * @export
 * @interface CreateShiftAreaSessionResponse
 */
export interface CreateShiftAreaSessionResponse {
    /**
     * 
     * @type {ShiftAreaSession}
     * @memberof CreateShiftAreaSessionResponse
     */
    data?: ShiftAreaSession;
}

/**
 * Check if a given object implements the CreateShiftAreaSessionResponse interface.
 */
export function instanceOfCreateShiftAreaSessionResponse(value: object): boolean {
    return true;
}

export function CreateShiftAreaSessionResponseFromJSON(json: any): CreateShiftAreaSessionResponse {
    return CreateShiftAreaSessionResponseFromJSONTyped(json, false);
}

export function CreateShiftAreaSessionResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateShiftAreaSessionResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': json['data'] == null ? undefined : ShiftAreaSessionFromJSON(json['data']),
    };
}

export function CreateShiftAreaSessionResponseToJSON(value?: CreateShiftAreaSessionResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'data': ShiftAreaSessionToJSON(value['data']),
    };
}

