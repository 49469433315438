import i18n from '@/i18n';
import { uniqueKeyBy } from '@/util/arrayFunctions';
import { employeeHasPermission } from '@/util/permissionFunctions';
import {
  AccessRole,
  AccessRolesPermissionPermissionEnum,
  Employee,
  EmployeeEmploymentTypeEnum,
  EmployeePayDetails,
  EmployeeStatusEnum,
} from '../../../api/v1';

export interface EmployeeWithFullName extends Employee {
  fullName: string;
}

export const employeeActive = ({ status }: Employee): boolean =>
  status === EmployeeStatusEnum.Active;

export const employeePending = ({ status }: Employee): boolean =>
  status === EmployeeStatusEnum.Pending;

export const employeeCurrent = (employee: Employee): boolean =>
  [EmployeeStatusEnum.Active, EmployeeStatusEnum.Pending].includes(
    employee.status,
  );

export const employeeArchived = ({ status }: Employee): boolean =>
  status === EmployeeStatusEnum.Archived;

export const needsToConfirmAccount = (employee: Employee): boolean =>
  employee.userId && employee.status === EmployeeStatusEnum.Pending;

export const fullName = (employee: Employee, fallback: string = ''): string => {
  if (!employee) {
    return fallback;
  }
  return `${employee.firstName} ${employee.lastName}`;
};

export const withFullName = (employee: Employee): EmployeeWithFullName => ({
  ...employee,
  fullName: fullName(employee),
});

export const employeeNameFilter =
  (q: string | null) =>
  (employee: Employee): boolean =>
    !q || fullName(employee).toLowerCase().includes(q.toLowerCase());

export const payrollIdFilter =
  (q: string | null, employeePay: EmployeePayDetails[]) =>
  ({ id }: Employee): boolean => {
    const payDetails = uniqueKeyBy('employeeId', employeePay)[id];

    return !q || payDetails?.payrollId?.toLowerCase().includes(q.toLowerCase());
  };

export const employeeAttributeFilter =
  (selectedIds: number[]) =>
  ({ employeeAttributeIds }: { employeeAttributeIds: number[] }) =>
    selectedIds.length === 0 ||
    selectedIds.some((id) => employeeAttributeIds.includes(id));

export const whereEmploymentType =
  (employmentTypes: EmployeeEmploymentTypeEnum[] = []) =>
  (employee: Employee) => {
    if (!employmentTypes.length) {
      // Ignore when no filter applied
      return true;
    }

    return employmentTypes.includes(employee.employmentType);
  };

export const pendingEmployeeWithUserFilter = (employee: Employee): boolean => {
  return !!employee.userId && employee.status === EmployeeStatusEnum.Pending;
};

export const isPotentialLeaveApprover =
  (targetEmployee?: Employee) => (candidate: Employee) => {
    const targetEmployeeId = targetEmployee ? targetEmployee.id : null;
    return (
      // Is an active employee
      employeeCurrent(candidate) &&
      !needsToConfirmAccount(candidate) &&
      // Can't approve themselves
      candidate.id !== targetEmployeeId &&
      // Can't approve someone they are approved by
      (!candidate.leaveApproverId ||
        candidate.leaveApproverId !== targetEmployeeId) &&
      // Must have a Shiftie user account
      candidate.userId !== null
    );
  };

export const isPotentialLineManager =
  (targetEmployee?: Employee) => (candidate: Employee) => {
    const targetEmployeeId = targetEmployee ? targetEmployee.id : null;
    return (
      // Is an active employee
      employeeCurrent(candidate) &&
      !needsToConfirmAccount(candidate) &&
      // Can't manage themselves
      candidate.id !== targetEmployeeId &&
      // Can't manage someone they are managed by
      (!candidate.lineManagerId ||
        candidate.lineManagerId !== targetEmployeeId) &&
      // Must have a Shiftie user account
      candidate.userId !== null
    );
  };

export const isPotentialPrimaryContact =
  (accessRoles: AccessRole[]) =>
  (employee: Employee): boolean => {
    const employeeAccessRoles = accessRoles.filter((a) =>
      employee.accessRoleIds.includes(a.id),
    );
    return (
      employeeHasPermission(
        employee,
        employeeAccessRoles,
        AccessRolesPermissionPermissionEnum.ManageTeam,
      ) && employeeActive(employee)
    );
  };

export const employeeContact = (
  employee: Employee,
): {
  phoneNumber: string | boolean;
  contactEmails: Array<object>;
} => {
  const activeEmails = [
    { label: i18n.t('label.contactEmail'), value: employee.contactEmail },
  ].filter((e) => !!e.value);

  return {
    phoneNumber: employee.phoneNumber || false,
    contactEmails: activeEmails,
  };
};

export const isZeroHourEmployee = (employee: Employee): boolean =>
  employee.workingHoursPerWeek === 0 &&
  employee.employmentType === EmployeeEmploymentTypeEnum.Casual;
