/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ShiftAreaSession } from './ShiftAreaSession';
import {
    ShiftAreaSessionFromJSON,
    ShiftAreaSessionFromJSONTyped,
    ShiftAreaSessionToJSON,
} from './ShiftAreaSession';

/**
 * 
 * @export
 * @interface UpdateShiftAreaSessionResponse
 */
export interface UpdateShiftAreaSessionResponse {
    /**
     * 
     * @type {ShiftAreaSession}
     * @memberof UpdateShiftAreaSessionResponse
     */
    data?: ShiftAreaSession;
}

/**
 * Check if a given object implements the UpdateShiftAreaSessionResponse interface.
 */
export function instanceOfUpdateShiftAreaSessionResponse(value: object): boolean {
    return true;
}

export function UpdateShiftAreaSessionResponseFromJSON(json: any): UpdateShiftAreaSessionResponse {
    return UpdateShiftAreaSessionResponseFromJSONTyped(json, false);
}

export function UpdateShiftAreaSessionResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateShiftAreaSessionResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': json['data'] == null ? undefined : ShiftAreaSessionFromJSON(json['data']),
    };
}

export function UpdateShiftAreaSessionResponseToJSON(value?: UpdateShiftAreaSessionResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'data': ShiftAreaSessionToJSON(value['data']),
    };
}

