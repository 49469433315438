import { isValidIsoDateString } from '@/util/dateFunctions';
import { limitNumber } from '@/util/mathFunctions';
import spacetime from 'spacetime';

const partsToIsoString = (year: number, month: number, day: number) =>
  `${year}-${String(month).padStart(2, '0')}-${String(day).padStart(2, '0')}`;

// eslint-disable-next-line no-use-before-define
export type PlainDateInput = PlainDate | string;

/**
 * This is our own temporary stand-in for representing calendar dates, until Temporal.PlainDate has landed in browsers.
 * It mostly follows the spec, but with a couple of convenience functions, to help us migrate in the meantime:
 *    - fromDate()
 *    - toDate()
 *    - getTimezoneCompare()
 */
export class PlainDate {
  private readonly _year: number;

  private readonly _month: number;

  private readonly _day: number;

  constructor(year: number, month: number, day: number) {
    const isoString = partsToIsoString(year, month, day);
    if (!isValidIsoDateString(isoString)) {
      throw new Error(`${isoString} is an invalid date`);
    }
    this._year = year;
    this._month = month;
    this._day = day;
  }

  /** Accepts an ISO date string in the format YYYY-MM-DD and returns a PlainDate object */
  static from(input: PlainDateInput): PlainDate {
    if (input instanceof PlainDate) {
      return new PlainDate(input._year, input._month, input._day);
    }
    if (typeof input === 'string') {
      if (!isValidIsoDateString(input)) {
        throw new Error(`ISO date string '${input}' is invalid`);
      }
      const parts = input.split('-').map((p) => parseInt(p, 10));

      return new PlainDate(parts[0], parts[1], parts[2]);
    }
    throw new Error(`Invalid input: ${input}`);
  }

  static _compare(a: PlainDate, b: PlainDate): number {
    if (a._year !== b._year) {
      return limitNumber(-1, 1, a._year - b._year);
    }
    if (a._month !== b._month) {
      return limitNumber(-1, 1, a._month - b._month);
    }
    return limitNumber(-1, 1, a._day - b._day);
  }

  static compare(a: PlainDateInput, b: PlainDateInput): number {
    return PlainDate._compare(
      a instanceof PlainDate ? a : PlainDate.from(a),
      b instanceof PlainDate ? b : PlainDate.from(b),
    );
  }

  /** Accepts an ISO date string in the format YYYY-MM-DD and returns a PlainDate object */
  static fromDate(date: Date, timezone: string): PlainDate {
    const isoString = spacetime(date, timezone).format('iso-short');
    return PlainDate.from(isoString);
  }

  /** Returns a native Date object representing the start of the day in the given timezone */
  toDate(timezone: string): Date {
    return spacetime(this.toString(), timezone).toNativeDate();
  }

  /** Returns an ISO date string in the format YYYY-MM-DD */
  toJSON(): string {
    return this.toString();
  }

  /** Returns an ISO date string in the format YYYY-MM-DD */
  toString(): string {
    return partsToIsoString(this._year, this._month, this._day);
  }
}
