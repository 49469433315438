/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Pagination } from './Pagination';
import {
    PaginationFromJSON,
    PaginationFromJSONTyped,
    PaginationToJSON,
} from './Pagination';

/**
 * 
 * @export
 * @interface ListEventEntitiesResponse
 */
export interface ListEventEntitiesResponse {
    /**
     * 
     * @type {Array<string>}
     * @memberof ListEventEntitiesResponse
     */
    data: Array<ListEventEntitiesResponseDataEnum>;
    /**
     * 
     * @type {Pagination}
     * @memberof ListEventEntitiesResponse
     */
    pagination: Pagination;
}

/**
* @export
* @enum {string}
*/
export enum ListEventEntitiesResponseDataEnum {
    Absence = 'Absence',
    AbsenceCategory = 'AbsenceCategory',
    AccessRole = 'AccessRole',
    AccessRolesPermission = 'AccessRolesPermission',
    BatchJobLog = 'BatchJobLog',
    BatchJobMessage = 'BatchJobMessage',
    BillingCancellation = 'BillingCancellation',
    BillingCredit = 'BillingCredit',
    BillingCreditDelta = 'BillingCreditDelta',
    BillingCreditRenewal = 'BillingCreditRenewal',
    BillingDetail = 'BillingDetail',
    BillingDowngrade = 'BillingDowngrade',
    BillingInvoice = 'BillingInvoice',
    BillingInvoiceLine = 'BillingInvoiceLine',
    BillingPayment = 'BillingPayment',
    BillingPaymentMethod = 'BillingPaymentMethod',
    BillingPlan = 'BillingPlan',
    BillingRenewal = 'BillingRenewal',
    BillingSubscription = 'BillingSubscription',
    BillingSubscriptionQuota = 'BillingSubscriptionQuota',
    BudgetSummary = 'BudgetSummary',
    ClockInPortal = 'ClockInPortal',
    ClockInPortalsLocation = 'ClockInPortalsLocation',
    ClockingEvent = 'ClockingEvent',
    ClockingSetting = 'ClockingSetting',
    Company = 'Company',
    CompanyFeatureFlag = 'CompanyFeatureFlag',
    CompanyFeatureGroup = 'CompanyFeatureGroup',
    CompanyNotificationChannel = 'CompanyNotificationChannel',
    CompanyOnboarding = 'CompanyOnboarding',
    CompanySetting = 'CompanySetting',
    DaySummary = 'DaySummary',
    DigiticketsConnection = 'DigiticketsConnection',
    DigiticketsScheduleMap = 'DigiticketsScheduleMap',
    Document = 'Document',
    DocumentRead = 'DocumentRead',
    DocumentsEmployee = 'DocumentsEmployee',
    DocumentsJobRole = 'DocumentsJobRole',
    EmergencyContact = 'EmergencyContact',
    Employee = 'Employee',
    EmployeeAbsenceSummary = 'EmployeeAbsenceSummary',
    EmployeeAttribute = 'EmployeeAttribute',
    EmployeeClockingProfile = 'EmployeeClockingProfile',
    EmployeeGroup = 'EmployeeGroup',
    EmployeeInvite = 'EmployeeInvite',
    EmployeeJobRole = 'EmployeeJobRole',
    EmployeeLeaveSummary = 'EmployeeLeaveSummary',
    EmployeeMessage = 'EmployeeMessage',
    EmployeeMessageRecipient = 'EmployeeMessageRecipient',
    EmployeeNotificationChannel = 'EmployeeNotificationChannel',
    EmployeeOnboarding = 'EmployeeOnboarding',
    EmployeePayDetail = 'EmployeePayDetail',
    EmployeePersonalDetail = 'EmployeePersonalDetail',
    EmployeesAccessRole = 'EmployeesAccessRole',
    EmployeesEmployeeAttribute = 'EmployeesEmployeeAttribute',
    EmployeesLocation = 'EmployeesLocation',
    EmployeesSchedule = 'EmployeesSchedule',
    FeaturesEnabled = 'FeaturesEnabled',
    History = 'History',
    HolidayRegion = 'HolidayRegion',
    IcalFeed = 'IcalFeed',
    JobRole = 'JobRole',
    LeaveAdjustment = 'LeaveAdjustment',
    LeaveAllowanceChunk = 'LeaveAllowanceChunk',
    LeavePeriod = 'LeavePeriod',
    LeavePeriodType = 'LeavePeriodType',
    LeavePeriodTypesAccrual = 'LeavePeriodTypesAccrual',
    LeavePolicy = 'LeavePolicy',
    LeavePolicyLeaveType = 'LeavePolicyLeaveType',
    LeaveRequest = 'LeaveRequest',
    LeaveSetting = 'LeaveSetting',
    LeaveType = 'LeaveType',
    Location = 'Location',
    MfaDevice = 'MfaDevice',
    NotificationSetting = 'NotificationSetting',
    Offer = 'Offer',
    OffersBehaviour = 'OffersBehaviour',
    OffersQuota = 'OffersQuota',
    OffersRedemption = 'OffersRedemption',
    OffersUsage = 'OffersUsage',
    OpenShiftResponse = 'OpenShiftResponse',
    PayCycle = 'PayCycle',
    PayPeriod = 'PayPeriod',
    PublicHoliday = 'PublicHoliday',
    Schedule = 'Schedule',
    ScheduleEvent = 'ScheduleEvent',
    ScheduleEventsSchedule = 'ScheduleEventsSchedule',
    ScheduleOverviewSummary = 'ScheduleOverviewSummary',
    ScheduleSetting = 'ScheduleSetting',
    ScheduleTemplate = 'ScheduleTemplate',
    ScheduledBreak = 'ScheduledBreak',
    Shift = 'Shift',
    ShiftArea = 'ShiftArea',
    ShiftAreaLocation = 'ShiftAreaLocation',
    ShiftAreaSession = 'ShiftAreaSession',
    ShiftConflict = 'ShiftConflict',
    ShiftSwap = 'ShiftSwap',
    ShiftTag = 'ShiftTag',
    ShiftTemplate = 'ShiftTemplate',
    ShiftTemplateBreak = 'ShiftTemplateBreak',
    ShiftTemplatesAreaSession = 'ShiftTemplatesAreaSession',
    ShiftTemplatesShiftAreaSession = 'ShiftTemplatesShiftAreaSession',
    ShiftTemplatesTag = 'ShiftTemplatesTag',
    Tag = 'Tag',
    TimesheetBreak = 'TimesheetBreak',
    TimesheetEntry = 'TimesheetEntry',
    TimesheetEntryExport = 'TimesheetEntryExport',
    TimesheetEntrySummary = 'TimesheetEntrySummary',
    TimesheetEntryTag = 'TimesheetEntryTag',
    TrekksoftClientCredential = 'TrekksoftClientCredential',
    Unavailability = 'Unavailability',
    Upload = 'Upload',
    User = 'User',
    WorkPattern = 'WorkPattern'
}


/**
 * Check if a given object implements the ListEventEntitiesResponse interface.
 */
export function instanceOfListEventEntitiesResponse(value: object): boolean {
    if (!('data' in value)) return false;
    if (!('pagination' in value)) return false;
    return true;
}

export function ListEventEntitiesResponseFromJSON(json: any): ListEventEntitiesResponse {
    return ListEventEntitiesResponseFromJSONTyped(json, false);
}

export function ListEventEntitiesResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListEventEntitiesResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': json['data'],
        'pagination': PaginationFromJSON(json['pagination']),
    };
}

export function ListEventEntitiesResponseToJSON(value?: ListEventEntitiesResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'data': value['data'],
        'pagination': PaginationToJSON(value['pagination']),
    };
}

