/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CreateShiftAreaData,
  CreateShiftAreaResponse,
  CreateShiftAreaSessionData,
  CreateShiftAreaSessionResponse,
  ListShiftAreasResponse,
  ShowShiftAreaResponse,
  UpdateShiftAreaData,
  UpdateShiftAreaResponse,
  UpdateShiftAreaSessionData,
  UpdateShiftAreaSessionResponse,
} from '../models/index';
import {
    CreateShiftAreaDataFromJSON,
    CreateShiftAreaDataToJSON,
    CreateShiftAreaResponseFromJSON,
    CreateShiftAreaResponseToJSON,
    CreateShiftAreaSessionDataFromJSON,
    CreateShiftAreaSessionDataToJSON,
    CreateShiftAreaSessionResponseFromJSON,
    CreateShiftAreaSessionResponseToJSON,
    ListShiftAreasResponseFromJSON,
    ListShiftAreasResponseToJSON,
    ShowShiftAreaResponseFromJSON,
    ShowShiftAreaResponseToJSON,
    UpdateShiftAreaDataFromJSON,
    UpdateShiftAreaDataToJSON,
    UpdateShiftAreaResponseFromJSON,
    UpdateShiftAreaResponseToJSON,
    UpdateShiftAreaSessionDataFromJSON,
    UpdateShiftAreaSessionDataToJSON,
    UpdateShiftAreaSessionResponseFromJSON,
    UpdateShiftAreaSessionResponseToJSON,
} from '../models/index';

export interface CreateShiftAreaRequest {
    _with?: Array<string>;
    createShiftAreaData?: CreateShiftAreaData;
}

export interface CreateShiftAreaSessionRequest {
    _with?: Array<string>;
    createShiftAreaSessionData?: CreateShiftAreaSessionData;
}

export interface DeleteShiftAreaSessionRequest {
    id: number;
}

export interface ListShiftAreasRequest {
    limit?: number;
    orderBy?: Array<string>;
    page?: number;
    perPage?: number;
    where?: { [key: string]: object; };
    _with?: Array<string>;
}

export interface ShowShiftAreaRequest {
    id: number;
    _with?: Array<string>;
}

export interface UpdateShiftAreaRequest {
    id: number;
    _with?: Array<string>;
    updateShiftAreaData?: UpdateShiftAreaData;
}

export interface UpdateShiftAreaSessionRequest {
    id: number;
    _with?: Array<string>;
    updateShiftAreaSessionData?: UpdateShiftAreaSessionData;
}

/**
 * 
 */
export class ShiftAreaApi extends runtime.BaseAPI {

    /**
     * Create a new Shift Area
     * Create Shift Area
     */
    async createShiftAreaRaw(requestParameters: CreateShiftAreaRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateShiftAreaResponse>> {
        const queryParameters: any = {};

        if (requestParameters['_with'] != null) {
            queryParameters['with'] = requestParameters['_with'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.shift-area-session.write"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/shift-areas`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateShiftAreaDataToJSON(requestParameters['createShiftAreaData']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateShiftAreaResponseFromJSON(jsonValue));
    }

    /**
     * Create a new Shift Area
     * Create Shift Area
     */
    async createShiftArea(requestParameters: CreateShiftAreaRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateShiftAreaResponse> {
        const response = await this.createShiftAreaRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a new Shift Area Session
     * Create Shift Area
     */
    async createShiftAreaSessionRaw(requestParameters: CreateShiftAreaSessionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateShiftAreaSessionResponse>> {
        const queryParameters: any = {};

        if (requestParameters['_with'] != null) {
            queryParameters['with'] = requestParameters['_with'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.shift-area-session.write"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/shift-area-sessions`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateShiftAreaSessionDataToJSON(requestParameters['createShiftAreaSessionData']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateShiftAreaSessionResponseFromJSON(jsonValue));
    }

    /**
     * Create a new Shift Area Session
     * Create Shift Area
     */
    async createShiftAreaSession(requestParameters: CreateShiftAreaSessionRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateShiftAreaSessionResponse> {
        const response = await this.createShiftAreaSessionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete a single Shift Area Session
     * Delete Shift Area Session
     */
    async deleteShiftAreaSessionRaw(requestParameters: DeleteShiftAreaSessionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling deleteShiftAreaSession().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.shift-area-session.write"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/shift-area-sessions/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a single Shift Area Session
     * Delete Shift Area Session
     */
    async deleteShiftAreaSession(requestParameters: DeleteShiftAreaSessionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteShiftAreaSessionRaw(requestParameters, initOverrides);
    }

    /**
     * List all Shift Areas
     * List Shift Area
     */
    async listShiftAreasRaw(requestParameters: ListShiftAreasRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListShiftAreasResponse>> {
        const queryParameters: any = {};

        if (requestParameters['limit'] != null) {
            queryParameters['limit'] = requestParameters['limit'];
        }

        if (requestParameters['orderBy'] != null) {
            queryParameters['orderBy'] = requestParameters['orderBy'];
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['perPage'] != null) {
            queryParameters['perPage'] = requestParameters['perPage'];
        }

        if (requestParameters['where'] != null) {
            queryParameters['where'] = requestParameters['where'];
        }

        if (requestParameters['_with'] != null) {
            queryParameters['with'] = requestParameters['_with'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.shift-area-session.read"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/shift-areas`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListShiftAreasResponseFromJSON(jsonValue));
    }

    /**
     * List all Shift Areas
     * List Shift Area
     */
    async listShiftAreas(requestParameters: ListShiftAreasRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListShiftAreasResponse> {
        const response = await this.listShiftAreasRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Show a single Shift Area
     * Show Shift Area
     */
    async showShiftAreaRaw(requestParameters: ShowShiftAreaRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ShowShiftAreaResponse>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling showShiftArea().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['_with'] != null) {
            queryParameters['with'] = requestParameters['_with'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.shift-area-session.read"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/shift-areas/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ShowShiftAreaResponseFromJSON(jsonValue));
    }

    /**
     * Show a single Shift Area
     * Show Shift Area
     */
    async showShiftArea(requestParameters: ShowShiftAreaRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ShowShiftAreaResponse> {
        const response = await this.showShiftAreaRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update a shift area
     * Update Shift Area
     */
    async updateShiftAreaRaw(requestParameters: UpdateShiftAreaRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UpdateShiftAreaResponse>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling updateShiftArea().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['_with'] != null) {
            queryParameters['with'] = requestParameters['_with'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.shift-area-session.write"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/shift-areas/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateShiftAreaDataToJSON(requestParameters['updateShiftAreaData']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UpdateShiftAreaResponseFromJSON(jsonValue));
    }

    /**
     * Update a shift area
     * Update Shift Area
     */
    async updateShiftArea(requestParameters: UpdateShiftAreaRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UpdateShiftAreaResponse> {
        const response = await this.updateShiftAreaRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update a shift area Session
     * Update Shift Area Session
     */
    async updateShiftAreaSessionRaw(requestParameters: UpdateShiftAreaSessionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UpdateShiftAreaSessionResponse>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling updateShiftAreaSession().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['_with'] != null) {
            queryParameters['with'] = requestParameters['_with'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.shift-area-session.write"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/shift-area-sessions/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateShiftAreaSessionDataToJSON(requestParameters['updateShiftAreaSessionData']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UpdateShiftAreaSessionResponseFromJSON(jsonValue));
    }

    /**
     * Update a shift area Session
     * Update Shift Area Session
     */
    async updateShiftAreaSession(requestParameters: UpdateShiftAreaSessionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UpdateShiftAreaSessionResponse> {
        const response = await this.updateShiftAreaSessionRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
