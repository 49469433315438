export enum VisualTheme {
  Warning = 'warning',
  Danger = 'danger',
  Success = 'success',
  Information = 'information',
  Disabled = 'disabled',
  Hint = 'hint',
}

export enum ColourClass {
  Blue = 'bg-blue-50 text-blue',
  Red = 'bg-red-50 text-red',
  Yellow = 'bg-yellow-lightest text-yellow',
}

export enum BackgroundColour {
  Green = 'bg-green',
  GreenOpacity15 = 'bg-green-50',
  Purple = 'bg-purple',
  Red = 'bg-red',
  RedLightest = 'bg-red-50',
  Yellow = 'bg-yellow',
  YellowLighter = 'bg-yellow-50',
}

export enum BubbleIconColour {
  'green' = 'green',
  'bright-green' = 'bright-green',
  'green-25' = 'green-25',
  'blue' = 'blue',
  'bright-blue' = 'bright-blue',
  'black' = 'black',
  'pink-50' = 'pink-50',
  'pink-300' = 'pink-300',
  'pink-400' = 'pink-400',
  'purple' = 'purple',
  'purple-500' = 'purple-500',
  'purple-900' = 'purple-900',
  'purple-50' = 'purple-50',
  'purple-800' = 'purple-800',
  'red' = 'red',
  'bright-red' = 'bright-red',
  'yellow' = 'yellow',
  'bright-yellow' = 'bright-yellow',
  'gray' = 'gray',
  'gray-300' = 'gray-300',
  'gray-500' = 'gray-500',
  'white' = 'white',
  'transparent' = 'transparent',
}

export enum EntityColour {
  RedDark = '#CC6770',
  Red = '#DB898E',
  RedLight = '#F1C2C0',

  PinkDark = '#C8709C',
  Pink = '#D28FB1',
  PinkLight = '#EBCAD6',

  PurpleDark = '#A494AB',
  Purple = '#C2B5C4',
  PurpleLight = '#E2D6D6',

  BlueDark = '#315379',
  Blue = '#7C96B0',
  BlueLight = '#C9D3D9',

  TealDark = '#2E8098',
  Teal = '#6DB6C4',
  TealLight = '#B9DBDC',

  GreenDark = '#648574',
  Green = '#87A18F',
  GreenLight = '#C4D0C0',

  BrownDark = '#9DA178',
  Brown = '#B6B692',
  BrownLight = '#D8D7BC',

  OrangeDark = '#F1A259',
  Orange = '#F2BE88',
  OrangeLight = '#FAD4AF',
}
export type EntityColourType = `${EntityColour}`;

export enum InlineDialogTheme {
  Warning = 'warning',
  Error = 'error',
  Info = 'info',
  Hint = 'hint',
}

export const SliderTrackWithTicksColour = {
  Purple: 'purple',
} as const;
export type SliderTrackWithTicksColourType =
  typeof SliderTrackWithTicksColour[keyof typeof SliderTrackWithTicksColour];

export const BadgeColour = {
  Error: 'text-red-700 bg-red-50',
  Info: 'text-gray-700 bg-white outline outline-gray-300 outline-1',
  Success: 'text-green-600 bg-green-100',
} as const;
