import {
  absenceApi,
  accessRoleApi,
  billingApi,
  calendarApi,
  clockInPortalApi,
  companyApi,
  documentApi,
  employeeApi,
  employeeAttributesApi,
  employeeGroupApi,
  employeeInviteApi,
  employeeJobRoleApi,
  employeeMessageApi,
  fetchAll,
  integrationApi,
  jobRoleApi,
  leaveAdjustmentApi,
  leavePolicyApi,
  leaveRequestApi,
  leaveTypeApi,
  locationApi,
  openShiftResponseApi,
  payCycleApi,
  payPeriodApi,
  publicHolidayApi,
  scheduleApi,
  scheduleEventApi,
  scheduleTemplateApi,
  shiftApi,
  shiftSwapApi,
  shiftTemplateApi,
  summariesApi,
  tagsApi,
  timesheetApi,
  unavailabilityApi,
  uploadApi,
  userApi,
  workPatternApi,
} from '@/api';
import {
  EntityConfig,
  EntityInstance,
  EntityName,
  Fetcher,
  FetcherBuilder,
  GetParameterType,
  ListFunction,
  ShowFunction,
  SingleEntityFetcherBuilder,
} from '@/lib/realtime/realtimeTypes';
import {
  AbsenceCategoryFromJSON,
  AbsenceFromJSON,
  AccessRoleFromJSON,
  BaseAPI,
  BillingPlanFromJSON,
  ClockInPortalFromJSON,
  CompanyFromJSON,
  CompanyNotificationChannelFromJSON,
  DaySummaryFromJSON,
  DigiticketsConnectionFromJSON,
  DigiticketsScheduleMapFromJSON,
  DocumentFromJSON,
  DocumentReadFromJSON,
  EmployeeAttributeFromJSON,
  EmployeeClockingProfileFromJSON,
  EmployeeFromJSON,
  EmployeeGroupFromJSON,
  EmployeeInviteFromJSON,
  EmployeeJobRoleFromJSON,
  EmployeeMessageFromJSON,
  EmployeeMessageRecipientFromJSON,
  EmployeeNotificationChannelFromJSON,
  EmployeePayDetailsFromJSON,
  EmployeePersonalDetailsFromJSON,
  IcalFeedFromJSON,
  JobRoleFromJSON,
  LeaveAdjustmentFromJSON,
  LeavePolicyFromJSON,
  LeaveRequestFromJSON,
  LeaveTypeFromJSON,
  ListEventEntitiesResponseDataEnum,
  LocationFromJSON,
  OfferFromJSON,
  OffersBehaviourFromJSON,
  OffersQuotaFromJSON,
  OpenShiftResponseFromJSON,
  PayCycleFromJSON,
  PayPeriodFromJSON,
  PublicHolidayFromJSON,
  ScheduleEventFromJSON,
  ScheduleFromJSON,
  ScheduleTemplateFromJSON,
  ShiftFromJSON,
  ShiftSwapFromJSON,
  ShiftTemplateFromJSON,
  TagFromJSON,
  TimesheetEntryFromJSON,
  TimesheetEntrySummaryFromJSON,
  TrekksoftClientCredentialFromJSON,
  UnavailabilityFromJSON,
  UploadFromJSON,
  UserFromJSON,
  WorkPatternFromJSON,
} from '../../../api/v1';

const Entity = ListEventEntitiesResponseDataEnum;
const genericFetcher: FetcherBuilder =
  (
    api: BaseAPI,
    listFunction: ListFunction,
    orderBy?: string[],
    _with?: string[],
  ): Fetcher =>
  async <T extends EntityName>(params: GetParameterType<T>, where) =>
    (
      await fetchAll(listFunction.bind(api), {
        ...params,
        where,
        ...(orderBy && { orderBy }),
        ...(_with && { _with }),
        perPage: 250,
      })
    ).data as EntityInstance<T>[];

const singleEntityFetcher: SingleEntityFetcherBuilder =
  (api: BaseAPI, showFunction: ShowFunction): Fetcher =>
  async <T extends EntityName>(params: GetParameterType<T>) =>
    [(await showFunction.bind(api)({ ...params })).data] as EntityInstance<T>[];

/**
 * This is where we configure how to fetch each entity type from the API.
 *
 * IMPORTANT: When adding to this, you'll also need to update the type mapping in EntityInterfaceMap.
 */
export const entityConfig: EntityConfig = {
  [Entity.AccessRole]: {
    fetcher: genericFetcher(
      accessRoleApi,
      accessRoleApi.listAccessRoles,
      ['name'],
      ['permissions'],
    ),
    entityMaker: AccessRoleFromJSON,
  },
  [Entity.BillingPlan]: {
    fetcher: genericFetcher(billingApi, billingApi.listBillingPlans, ['name']),
    entityMaker: BillingPlanFromJSON,
  },
  [Entity.OffersBehaviour]: {
    fetcher: genericFetcher(billingApi, billingApi.listOffersBehaviours),
    entityMaker: OffersBehaviourFromJSON,
  },
  [Entity.OffersQuota]: {
    fetcher: genericFetcher(billingApi, billingApi.listOffersQuota),
    entityMaker: OffersQuotaFromJSON,
  },
  [Entity.Offer]: {
    fetcher: genericFetcher(billingApi, billingApi.listOffers),
    entityMaker: OfferFromJSON,
  },
  [Entity.ClockInPortal]: {
    fetcher: genericFetcher(
      clockInPortalApi,
      clockInPortalApi.listClockInPortals,
    ),
    entityMaker: ClockInPortalFromJSON,
  },
  [Entity.Company]: {
    fetcher: genericFetcher(
      companyApi,
      companyApi.listCompaniesBelongingToUser,
      ['name'],
    ),
    entityMaker: CompanyFromJSON,
  },
  [Entity.CompanyNotificationChannel]: {
    fetcher: genericFetcher(
      companyApi,
      companyApi.listCompanyNotificationChannels,
    ),
    entityMaker: CompanyNotificationChannelFromJSON,
  },
  [Entity.DigiticketsConnection]: {
    fetcher: genericFetcher(
      integrationApi(),
      integrationApi().listDigiticketsConnections,
    ),
    entityMaker: DigiticketsConnectionFromJSON,
  },
  [Entity.DigiticketsScheduleMap]: {
    fetcher: genericFetcher(
      integrationApi(),
      integrationApi().listDigiticketsScheduleMaps,
    ),
    entityMaker: DigiticketsScheduleMapFromJSON,
  },
  [Entity.Document]: {
    fetcher: genericFetcher(documentApi, documentApi.listDocuments),
    entityMaker: DocumentFromJSON,
  },
  [Entity.DocumentRead]: {
    fetcher: genericFetcher(documentApi, documentApi.listDocumentReads, [
      'createdAt',
    ]),
    entityMaker: DocumentReadFromJSON,
  },
  [Entity.Employee]: {
    fetcher: genericFetcher(
      employeeApi,
      employeeApi.listEmployees,
      ['firstName', 'lastName'],
      ['photo'],
    ),
    entityMaker: EmployeeFromJSON,
  },
  [Entity.EmployeeClockingProfile]: {
    fetcher: genericFetcher(
      employeeApi,
      employeeApi.listEmployeeClockingProfiles,
    ),
    entityMaker: EmployeeClockingProfileFromJSON,
  },
  [Entity.EmployeeInvite]: {
    fetcher: genericFetcher(
      employeeInviteApi,
      employeeInviteApi.listEmployeeInvites,
    ),
    entityMaker: EmployeeInviteFromJSON,
  },
  [Entity.EmployeeJobRole]: {
    fetcher: genericFetcher(
      employeeJobRoleApi,
      employeeJobRoleApi.listEmployeeJobRoles,
    ),
    entityMaker: EmployeeJobRoleFromJSON,
  },
  [Entity.EmployeeMessage]: {
    fetcher: genericFetcher(
      employeeMessageApi,
      employeeMessageApi.listEmployeeMessages,
      ['sentAt'],
    ),
    entityMaker: EmployeeMessageFromJSON,
  },
  [Entity.EmployeeMessageRecipient]: {
    fetcher: genericFetcher(
      employeeMessageApi,
      employeeMessageApi.listEmployeeMessageRecipients,
    ),
    entityMaker: EmployeeMessageRecipientFromJSON,
  },
  [Entity.EmployeePayDetail]: {
    fetcher: genericFetcher(employeeApi, employeeApi.listEmployeePayDetail),
    entityMaker: EmployeePayDetailsFromJSON,
  },
  [Entity.EmployeePersonalDetail]: {
    fetcher: genericFetcher(
      employeeApi,
      employeeApi.listEmployeePersonalDetails,
    ),
    entityMaker: EmployeePersonalDetailsFromJSON,
  },
  [Entity.EmployeeAttribute]: {
    fetcher: genericFetcher(
      employeeAttributesApi,
      employeeAttributesApi.listEmployeeAttributes,
      ['name'],
    ),
    entityMaker: EmployeeAttributeFromJSON,
  },
  [Entity.EmployeeGroup]: {
    fetcher: genericFetcher(
      employeeGroupApi,
      employeeGroupApi.listEmployeeGroups,
    ),
    entityMaker: EmployeeGroupFromJSON,
  },
  [Entity.EmployeeNotificationChannel]: {
    fetcher: genericFetcher(
      employeeApi,
      employeeApi.listEmployeeNotificationChannels,
    ),
    entityMaker: EmployeeNotificationChannelFromJSON,
  },
  [Entity.IcalFeed]: {
    fetcher: genericFetcher(calendarApi, calendarApi.listCalendarFeeds),
    entityMaker: IcalFeedFromJSON,
  },
  [Entity.JobRole]: {
    fetcher: genericFetcher(jobRoleApi, jobRoleApi.listJobRoles, ['name']),
    entityMaker: JobRoleFromJSON,
  },
  [Entity.OpenShiftResponse]: {
    fetcher: genericFetcher(
      openShiftResponseApi,
      openShiftResponseApi.listOpenShiftResponses,
      ['createdAt'],
    ),
    entityMaker: OpenShiftResponseFromJSON,
  },
  [Entity.LeaveAdjustment]: {
    fetcher: genericFetcher(
      leaveAdjustmentApi,
      leaveAdjustmentApi.listLeaveAdjustments,
      ['createdAt'],
    ),
    entityMaker: LeaveAdjustmentFromJSON,
  },
  [Entity.LeavePolicy]: {
    fetcher: genericFetcher(leavePolicyApi, leavePolicyApi.listLeavePolicies, [
      'name',
    ]),
    entityMaker: LeavePolicyFromJSON,
  },
  [Entity.LeaveRequest]: {
    fetcher: genericFetcher(
      leaveRequestApi,
      leaveRequestApi.listLeaveRequests,
      ['startDate'],
    ),
    entityMaker: LeaveRequestFromJSON,
  },
  [Entity.LeaveType]: {
    fetcher: genericFetcher(leaveTypeApi, leaveTypeApi.listLeaveTypes, [
      'name',
    ]),
    entityMaker: LeaveTypeFromJSON,
  },
  [Entity.Location]: {
    fetcher: genericFetcher(locationApi, locationApi.listLocations, ['name']),
    entityMaker: LocationFromJSON,
  },
  [Entity.PayPeriod]: {
    fetcher: genericFetcher(payPeriodApi, payPeriodApi.listPayPeriods, [
      '-startsOn',
    ]),
    entityMaker: PayPeriodFromJSON,
  },
  [Entity.PayCycle]: {
    fetcher: genericFetcher(payCycleApi, payCycleApi.listPayCycles, [
      'startsOn',
    ]),
    entityMaker: PayCycleFromJSON,
  },
  [Entity.PublicHoliday]: {
    fetcher: genericFetcher(
      publicHolidayApi,
      publicHolidayApi.listPublicHolidays,
      ['date'],
    ),
    entityMaker: PublicHolidayFromJSON,
  },
  [Entity.Schedule]: {
    fetcher: genericFetcher(scheduleApi, scheduleApi.listSchedules, ['name']),
    entityMaker: ScheduleFromJSON,
  },
  [Entity.ScheduleEvent]: {
    fetcher: genericFetcher(
      scheduleEventApi,
      scheduleEventApi.listScheduleEvents,
      ['dateStart'],
    ),
    entityMaker: ScheduleEventFromJSON,
  },
  [Entity.ScheduleTemplate]: {
    fetcher: genericFetcher(
      scheduleTemplateApi,
      scheduleTemplateApi.listScheduleTemplates,
    ),
    entityMaker: ScheduleTemplateFromJSON,
  },
  [Entity.Shift]: {
    fetcher: genericFetcher(shiftApi, shiftApi.listShifts, ['startsAt']),
    entityMaker: ShiftFromJSON,
  },
  [Entity.ShiftTemplate]: {
    fetcher: genericFetcher(
      shiftTemplateApi,
      shiftTemplateApi.listShiftTemplates,
      ['startHour', 'startMinute'],
    ),
    entityMaker: ShiftTemplateFromJSON,
  },
  [Entity.ShiftSwap]: {
    fetcher: genericFetcher(shiftSwapApi, shiftSwapApi.listShiftSwaps),
    entityMaker: ShiftSwapFromJSON,
  },
  [Entity.Absence]: {
    fetcher: genericFetcher(absenceApi, absenceApi.listAbsence, ['startedOn']),
    entityMaker: AbsenceFromJSON,
  },
  [Entity.AbsenceCategory]: {
    fetcher: genericFetcher(absenceApi, absenceApi.listAbsenceCategories, [
      'name',
    ]),
    entityMaker: AbsenceCategoryFromJSON,
  },
  [Entity.Tag]: {
    fetcher: genericFetcher(tagsApi, tagsApi.listTags, ['name']),
    entityMaker: TagFromJSON,
  },
  [Entity.TimesheetEntry]: {
    fetcher: genericFetcher(timesheetApi, timesheetApi.listTimesheetEntries, [
      'startedAt',
    ]),
    entityMaker: TimesheetEntryFromJSON,
  },
  [Entity.TimesheetEntrySummary]: {
    fetcher: genericFetcher(summariesApi, summariesApi.listTimesheetSummaries),
    entityMaker: TimesheetEntrySummaryFromJSON,
  },
  [Entity.TrekksoftClientCredential]: {
    fetcher: genericFetcher(
      integrationApi(),
      integrationApi().listTrekksoftClientCredentials,
    ),
    entityMaker: TrekksoftClientCredentialFromJSON,
  },
  [Entity.Unavailability]: {
    fetcher: genericFetcher(
      unavailabilityApi,
      unavailabilityApi.listUnavailability,
      ['startsAt'],
    ),
    entityMaker: UnavailabilityFromJSON,
  },
  [Entity.Upload]: {
    fetcher: genericFetcher(uploadApi, uploadApi.listUploads),
    entityMaker: UploadFromJSON,
  },
  [Entity.User]: {
    fetcher: genericFetcher(userApi, userApi.listCompanyUsers),
    entityMaker: UserFromJSON,
  },
  [Entity.WorkPattern]: {
    fetcher: genericFetcher(workPatternApi, workPatternApi.listWorkPatterns, [
      'name',
    ]),
    entityMaker: WorkPatternFromJSON,
  },
  [Entity.DaySummary]: {
    fetcher: singleEntityFetcher(summariesApi, summariesApi.showDaySummary),
    entityMaker: DaySummaryFromJSON,
  },
};
