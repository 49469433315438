/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface WebhookSubscription
 */
export interface WebhookSubscription {
    /**
     * 
     * @type {number}
     * @memberof WebhookSubscription
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof WebhookSubscription
     */
    targetUrl: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof WebhookSubscription
     */
    events: Array<WebhookSubscriptionEventsEnum>;
    /**
     * 
     * @type {string}
     * @memberof WebhookSubscription
     */
    publicKey: string;
    /**
     * 
     * @type {string}
     * @memberof WebhookSubscription
     */
    errorReportingEmail: string;
}

/**
* @export
* @enum {string}
*/
export enum WebhookSubscriptionEventsEnum {
    AbsenceCreated = 'Absence_Created',
    AbsenceDeleted = 'Absence_Deleted',
    AbsenceUpdated = 'Absence_Updated',
    AbsenceCategoryCreated = 'AbsenceCategory_Created',
    AbsenceCategoryDeleted = 'AbsenceCategory_Deleted',
    AbsenceCategoryUpdated = 'AbsenceCategory_Updated',
    AccessRoleCreated = 'AccessRole_Created',
    AccessRoleDeleted = 'AccessRole_Deleted',
    AccessRoleUpdated = 'AccessRole_Updated',
    AccessRolesPermissionCreated = 'AccessRolesPermission_Created',
    AccessRolesPermissionDeleted = 'AccessRolesPermission_Deleted',
    AccessRolesPermissionUpdated = 'AccessRolesPermission_Updated',
    BatchJobLogCreated = 'BatchJobLog_Created',
    BatchJobLogDeleted = 'BatchJobLog_Deleted',
    BatchJobLogUpdated = 'BatchJobLog_Updated',
    BatchJobMessageReceived = 'BatchJobMessage_Received',
    BillingCancellationCreated = 'BillingCancellation_Created',
    BillingCancellationDeleted = 'BillingCancellation_Deleted',
    BillingCancellationUpdated = 'BillingCancellation_Updated',
    BillingCreditCreated = 'BillingCredit_Created',
    BillingCreditDeleted = 'BillingCredit_Deleted',
    BillingCreditUpdated = 'BillingCredit_Updated',
    BillingCreditDeltaCreated = 'BillingCreditDelta_Created',
    BillingCreditDeltaDeleted = 'BillingCreditDelta_Deleted',
    BillingCreditDeltaUpdated = 'BillingCreditDelta_Updated',
    BillingCreditRenewalCreated = 'BillingCreditRenewal_Created',
    BillingCreditRenewalDeleted = 'BillingCreditRenewal_Deleted',
    BillingCreditRenewalUpdated = 'BillingCreditRenewal_Updated',
    BillingDetailCreated = 'BillingDetail_Created',
    BillingDetailDeleted = 'BillingDetail_Deleted',
    BillingDetailUpdated = 'BillingDetail_Updated',
    BillingDowngradeCreated = 'BillingDowngrade_Created',
    BillingDowngradeDeleted = 'BillingDowngrade_Deleted',
    BillingDowngradeUpdated = 'BillingDowngrade_Updated',
    BillingInvoiceCreated = 'BillingInvoice_Created',
    BillingInvoiceDeleted = 'BillingInvoice_Deleted',
    BillingInvoiceUpdated = 'BillingInvoice_Updated',
    BillingInvoiceLineCreated = 'BillingInvoiceLine_Created',
    BillingInvoiceLineDeleted = 'BillingInvoiceLine_Deleted',
    BillingInvoiceLineUpdated = 'BillingInvoiceLine_Updated',
    BillingPaymentCreated = 'BillingPayment_Created',
    BillingPaymentDeleted = 'BillingPayment_Deleted',
    BillingPaymentUpdated = 'BillingPayment_Updated',
    BillingPaymentMethodCreated = 'BillingPaymentMethod_Created',
    BillingPaymentMethodDeleted = 'BillingPaymentMethod_Deleted',
    BillingPaymentMethodUpdated = 'BillingPaymentMethod_Updated',
    BillingPlanCreated = 'BillingPlan_Created',
    BillingPlanDeleted = 'BillingPlan_Deleted',
    BillingPlanUpdated = 'BillingPlan_Updated',
    BillingRenewalCreated = 'BillingRenewal_Created',
    BillingRenewalDeleted = 'BillingRenewal_Deleted',
    BillingRenewalUpdated = 'BillingRenewal_Updated',
    BillingSubscriptionCreated = 'BillingSubscription_Created',
    BillingSubscriptionDeleted = 'BillingSubscription_Deleted',
    BillingSubscriptionUpdated = 'BillingSubscription_Updated',
    BillingSubscriptionQuotaCreated = 'BillingSubscriptionQuota_Created',
    BillingSubscriptionQuotaDeleted = 'BillingSubscriptionQuota_Deleted',
    BillingSubscriptionQuotaUpdated = 'BillingSubscriptionQuota_Updated',
    BudgetSummaryCreated = 'BudgetSummary_Created',
    BudgetSummaryDeleted = 'BudgetSummary_Deleted',
    BudgetSummaryUpdated = 'BudgetSummary_Updated',
    ClockInPortalCreated = 'ClockInPortal_Created',
    ClockInPortalDeleted = 'ClockInPortal_Deleted',
    ClockInPortalUpdated = 'ClockInPortal_Updated',
    ClockInPortalsLocationCreated = 'ClockInPortalsLocation_Created',
    ClockInPortalsLocationDeleted = 'ClockInPortalsLocation_Deleted',
    ClockInPortalsLocationUpdated = 'ClockInPortalsLocation_Updated',
    ClockingEventCreated = 'ClockingEvent_Created',
    ClockingEventDeleted = 'ClockingEvent_Deleted',
    ClockingEventUpdated = 'ClockingEvent_Updated',
    ClockingSettingCreated = 'ClockingSetting_Created',
    ClockingSettingDeleted = 'ClockingSetting_Deleted',
    ClockingSettingUpdated = 'ClockingSetting_Updated',
    CompanyCreated = 'Company_Created',
    CompanyDeleted = 'Company_Deleted',
    CompanyUpdated = 'Company_Updated',
    CompanyFeatureFlagCreated = 'CompanyFeatureFlag_Created',
    CompanyFeatureFlagDeleted = 'CompanyFeatureFlag_Deleted',
    CompanyFeatureFlagUpdated = 'CompanyFeatureFlag_Updated',
    CompanyFeatureGroupCreated = 'CompanyFeatureGroup_Created',
    CompanyFeatureGroupDeleted = 'CompanyFeatureGroup_Deleted',
    CompanyFeatureGroupUpdated = 'CompanyFeatureGroup_Updated',
    CompanyNotificationChannelCreated = 'CompanyNotificationChannel_Created',
    CompanyNotificationChannelDeleted = 'CompanyNotificationChannel_Deleted',
    CompanyNotificationChannelUpdated = 'CompanyNotificationChannel_Updated',
    CompanyOnboardingCreated = 'CompanyOnboarding_Created',
    CompanyOnboardingDeleted = 'CompanyOnboarding_Deleted',
    CompanyOnboardingUpdated = 'CompanyOnboarding_Updated',
    CompanySettingCreated = 'CompanySetting_Created',
    CompanySettingDeleted = 'CompanySetting_Deleted',
    CompanySettingUpdated = 'CompanySetting_Updated',
    DaySummaryCreated = 'DaySummary_Created',
    DaySummaryDeleted = 'DaySummary_Deleted',
    DaySummaryUpdated = 'DaySummary_Updated',
    DigiticketsConnectionCreated = 'DigiticketsConnection_Created',
    DigiticketsConnectionDeleted = 'DigiticketsConnection_Deleted',
    DigiticketsConnectionUpdated = 'DigiticketsConnection_Updated',
    DigiticketsScheduleMapCreated = 'DigiticketsScheduleMap_Created',
    DigiticketsScheduleMapDeleted = 'DigiticketsScheduleMap_Deleted',
    DigiticketsScheduleMapUpdated = 'DigiticketsScheduleMap_Updated',
    DocumentCreated = 'Document_Created',
    DocumentDeleted = 'Document_Deleted',
    DocumentUpdated = 'Document_Updated',
    DocumentReadCreated = 'DocumentRead_Created',
    DocumentReadDeleted = 'DocumentRead_Deleted',
    DocumentReadUpdated = 'DocumentRead_Updated',
    DocumentsEmployeeCreated = 'DocumentsEmployee_Created',
    DocumentsEmployeeDeleted = 'DocumentsEmployee_Deleted',
    DocumentsEmployeeUpdated = 'DocumentsEmployee_Updated',
    DocumentsJobRoleCreated = 'DocumentsJobRole_Created',
    DocumentsJobRoleDeleted = 'DocumentsJobRole_Deleted',
    DocumentsJobRoleUpdated = 'DocumentsJobRole_Updated',
    EmergencyContactCreated = 'EmergencyContact_Created',
    EmergencyContactDeleted = 'EmergencyContact_Deleted',
    EmergencyContactUpdated = 'EmergencyContact_Updated',
    EmployeeCreated = 'Employee_Created',
    EmployeeDeleted = 'Employee_Deleted',
    EmployeeUpdated = 'Employee_Updated',
    EmployeeAbsenceSummaryCreated = 'EmployeeAbsenceSummary_Created',
    EmployeeAbsenceSummaryDeleted = 'EmployeeAbsenceSummary_Deleted',
    EmployeeAbsenceSummaryUpdated = 'EmployeeAbsenceSummary_Updated',
    EmployeeAttributeCreated = 'EmployeeAttribute_Created',
    EmployeeAttributeDeleted = 'EmployeeAttribute_Deleted',
    EmployeeAttributeUpdated = 'EmployeeAttribute_Updated',
    EmployeeClockingProfileCreated = 'EmployeeClockingProfile_Created',
    EmployeeClockingProfileDeleted = 'EmployeeClockingProfile_Deleted',
    EmployeeClockingProfileUpdated = 'EmployeeClockingProfile_Updated',
    EmployeeGroupCreated = 'EmployeeGroup_Created',
    EmployeeGroupDeleted = 'EmployeeGroup_Deleted',
    EmployeeGroupUpdated = 'EmployeeGroup_Updated',
    EmployeeInviteCreated = 'EmployeeInvite_Created',
    EmployeeInviteDeleted = 'EmployeeInvite_Deleted',
    EmployeeInviteUpdated = 'EmployeeInvite_Updated',
    EmployeeJobRoleCreated = 'EmployeeJobRole_Created',
    EmployeeJobRoleDeleted = 'EmployeeJobRole_Deleted',
    EmployeeJobRoleUpdated = 'EmployeeJobRole_Updated',
    EmployeeLeaveSummaryCreated = 'EmployeeLeaveSummary_Created',
    EmployeeLeaveSummaryDeleted = 'EmployeeLeaveSummary_Deleted',
    EmployeeLeaveSummaryUpdated = 'EmployeeLeaveSummary_Updated',
    EmployeeMessageCreated = 'EmployeeMessage_Created',
    EmployeeMessageDeleted = 'EmployeeMessage_Deleted',
    EmployeeMessageUpdated = 'EmployeeMessage_Updated',
    EmployeeMessageRecipientCreated = 'EmployeeMessageRecipient_Created',
    EmployeeMessageRecipientDeleted = 'EmployeeMessageRecipient_Deleted',
    EmployeeMessageRecipientUpdated = 'EmployeeMessageRecipient_Updated',
    EmployeeNotificationChannelCreated = 'EmployeeNotificationChannel_Created',
    EmployeeNotificationChannelDeleted = 'EmployeeNotificationChannel_Deleted',
    EmployeeNotificationChannelUpdated = 'EmployeeNotificationChannel_Updated',
    EmployeeOnboardingCreated = 'EmployeeOnboarding_Created',
    EmployeeOnboardingDeleted = 'EmployeeOnboarding_Deleted',
    EmployeeOnboardingUpdated = 'EmployeeOnboarding_Updated',
    EmployeePayDetailCreated = 'EmployeePayDetail_Created',
    EmployeePayDetailDeleted = 'EmployeePayDetail_Deleted',
    EmployeePayDetailUpdated = 'EmployeePayDetail_Updated',
    EmployeePersonalDetailCreated = 'EmployeePersonalDetail_Created',
    EmployeePersonalDetailDeleted = 'EmployeePersonalDetail_Deleted',
    EmployeePersonalDetailUpdated = 'EmployeePersonalDetail_Updated',
    EmployeesAccessRoleCreated = 'EmployeesAccessRole_Created',
    EmployeesAccessRoleDeleted = 'EmployeesAccessRole_Deleted',
    EmployeesAccessRoleUpdated = 'EmployeesAccessRole_Updated',
    EmployeesEmployeeAttributeCreated = 'EmployeesEmployeeAttribute_Created',
    EmployeesEmployeeAttributeDeleted = 'EmployeesEmployeeAttribute_Deleted',
    EmployeesEmployeeAttributeUpdated = 'EmployeesEmployeeAttribute_Updated',
    EmployeesLocationCreated = 'EmployeesLocation_Created',
    EmployeesLocationDeleted = 'EmployeesLocation_Deleted',
    EmployeesLocationUpdated = 'EmployeesLocation_Updated',
    EmployeesScheduleCreated = 'EmployeesSchedule_Created',
    EmployeesScheduleDeleted = 'EmployeesSchedule_Deleted',
    EmployeesScheduleUpdated = 'EmployeesSchedule_Updated',
    FeaturesEnabledCreated = 'FeaturesEnabled_Created',
    FeaturesEnabledDeleted = 'FeaturesEnabled_Deleted',
    FeaturesEnabledUpdated = 'FeaturesEnabled_Updated',
    HistoryCreated = 'History_Created',
    HistoryDeleted = 'History_Deleted',
    HistoryUpdated = 'History_Updated',
    HolidayRegionCreated = 'HolidayRegion_Created',
    HolidayRegionDeleted = 'HolidayRegion_Deleted',
    HolidayRegionUpdated = 'HolidayRegion_Updated',
    IcalFeedCreated = 'IcalFeed_Created',
    IcalFeedDeleted = 'IcalFeed_Deleted',
    IcalFeedUpdated = 'IcalFeed_Updated',
    JobRoleCreated = 'JobRole_Created',
    JobRoleDeleted = 'JobRole_Deleted',
    JobRoleUpdated = 'JobRole_Updated',
    LeaveAdjustmentCreated = 'LeaveAdjustment_Created',
    LeaveAdjustmentDeleted = 'LeaveAdjustment_Deleted',
    LeaveAdjustmentUpdated = 'LeaveAdjustment_Updated',
    LeaveAllowanceChunkCreated = 'LeaveAllowanceChunk_Created',
    LeaveAllowanceChunkDeleted = 'LeaveAllowanceChunk_Deleted',
    LeaveAllowanceChunkUpdated = 'LeaveAllowanceChunk_Updated',
    LeavePeriodCreated = 'LeavePeriod_Created',
    LeavePeriodDeleted = 'LeavePeriod_Deleted',
    LeavePeriodUpdated = 'LeavePeriod_Updated',
    LeavePeriodTypeCreated = 'LeavePeriodType_Created',
    LeavePeriodTypeDeleted = 'LeavePeriodType_Deleted',
    LeavePeriodTypeUpdated = 'LeavePeriodType_Updated',
    LeavePeriodTypesAccrualCreated = 'LeavePeriodTypesAccrual_Created',
    LeavePeriodTypesAccrualDeleted = 'LeavePeriodTypesAccrual_Deleted',
    LeavePeriodTypesAccrualUpdated = 'LeavePeriodTypesAccrual_Updated',
    LeavePolicyCreated = 'LeavePolicy_Created',
    LeavePolicyDeleted = 'LeavePolicy_Deleted',
    LeavePolicyUpdated = 'LeavePolicy_Updated',
    LeavePolicyLeaveTypeCreated = 'LeavePolicyLeaveType_Created',
    LeavePolicyLeaveTypeDeleted = 'LeavePolicyLeaveType_Deleted',
    LeavePolicyLeaveTypeUpdated = 'LeavePolicyLeaveType_Updated',
    LeaveRequestCreated = 'LeaveRequest_Created',
    LeaveRequestDeleted = 'LeaveRequest_Deleted',
    LeaveRequestUpdated = 'LeaveRequest_Updated',
    LeaveSettingCreated = 'LeaveSetting_Created',
    LeaveSettingDeleted = 'LeaveSetting_Deleted',
    LeaveSettingUpdated = 'LeaveSetting_Updated',
    LeaveTypeCreated = 'LeaveType_Created',
    LeaveTypeDeleted = 'LeaveType_Deleted',
    LeaveTypeUpdated = 'LeaveType_Updated',
    LocationCreated = 'Location_Created',
    LocationDeleted = 'Location_Deleted',
    LocationUpdated = 'Location_Updated',
    MfaDeviceCreated = 'MfaDevice_Created',
    MfaDeviceDeleted = 'MfaDevice_Deleted',
    MfaDeviceUpdated = 'MfaDevice_Updated',
    NotificationSettingCreated = 'NotificationSetting_Created',
    NotificationSettingDeleted = 'NotificationSetting_Deleted',
    NotificationSettingUpdated = 'NotificationSetting_Updated',
    OfferCreated = 'Offer_Created',
    OfferDeleted = 'Offer_Deleted',
    OfferUpdated = 'Offer_Updated',
    OffersBehaviourCreated = 'OffersBehaviour_Created',
    OffersBehaviourDeleted = 'OffersBehaviour_Deleted',
    OffersBehaviourUpdated = 'OffersBehaviour_Updated',
    OffersQuotaCreated = 'OffersQuota_Created',
    OffersQuotaDeleted = 'OffersQuota_Deleted',
    OffersQuotaUpdated = 'OffersQuota_Updated',
    OffersRedemptionCreated = 'OffersRedemption_Created',
    OffersRedemptionDeleted = 'OffersRedemption_Deleted',
    OffersRedemptionUpdated = 'OffersRedemption_Updated',
    OffersUsageCreated = 'OffersUsage_Created',
    OffersUsageDeleted = 'OffersUsage_Deleted',
    OffersUsageUpdated = 'OffersUsage_Updated',
    OpenShiftResponseCreated = 'OpenShiftResponse_Created',
    OpenShiftResponseDeleted = 'OpenShiftResponse_Deleted',
    OpenShiftResponseUpdated = 'OpenShiftResponse_Updated',
    PayCycleCreated = 'PayCycle_Created',
    PayCycleDeleted = 'PayCycle_Deleted',
    PayCycleUpdated = 'PayCycle_Updated',
    PayPeriodCreated = 'PayPeriod_Created',
    PayPeriodDeleted = 'PayPeriod_Deleted',
    PayPeriodUpdated = 'PayPeriod_Updated',
    PublicHolidayCreated = 'PublicHoliday_Created',
    PublicHolidayDeleted = 'PublicHoliday_Deleted',
    PublicHolidayUpdated = 'PublicHoliday_Updated',
    ScheduleCreated = 'Schedule_Created',
    ScheduleDeleted = 'Schedule_Deleted',
    ScheduleUpdated = 'Schedule_Updated',
    ScheduleEventCreated = 'ScheduleEvent_Created',
    ScheduleEventDeleted = 'ScheduleEvent_Deleted',
    ScheduleEventUpdated = 'ScheduleEvent_Updated',
    ScheduleEventsScheduleCreated = 'ScheduleEventsSchedule_Created',
    ScheduleEventsScheduleDeleted = 'ScheduleEventsSchedule_Deleted',
    ScheduleEventsScheduleUpdated = 'ScheduleEventsSchedule_Updated',
    ScheduleOverviewSummaryCreated = 'ScheduleOverviewSummary_Created',
    ScheduleOverviewSummaryDeleted = 'ScheduleOverviewSummary_Deleted',
    ScheduleOverviewSummaryUpdated = 'ScheduleOverviewSummary_Updated',
    ScheduleSettingCreated = 'ScheduleSetting_Created',
    ScheduleSettingDeleted = 'ScheduleSetting_Deleted',
    ScheduleSettingUpdated = 'ScheduleSetting_Updated',
    ScheduleTemplateCreated = 'ScheduleTemplate_Created',
    ScheduleTemplateDeleted = 'ScheduleTemplate_Deleted',
    ScheduleTemplateUpdated = 'ScheduleTemplate_Updated',
    ScheduledBreakCreated = 'ScheduledBreak_Created',
    ScheduledBreakDeleted = 'ScheduledBreak_Deleted',
    ScheduledBreakUpdated = 'ScheduledBreak_Updated',
    ShiftCreated = 'Shift_Created',
    ShiftDeleted = 'Shift_Deleted',
    ShiftUpdated = 'Shift_Updated',
    ShiftAreaCreated = 'ShiftArea_Created',
    ShiftAreaDeleted = 'ShiftArea_Deleted',
    ShiftAreaUpdated = 'ShiftArea_Updated',
    ShiftAreaLocationCreated = 'ShiftAreaLocation_Created',
    ShiftAreaLocationDeleted = 'ShiftAreaLocation_Deleted',
    ShiftAreaLocationUpdated = 'ShiftAreaLocation_Updated',
    ShiftAreaSessionCreated = 'ShiftAreaSession_Created',
    ShiftAreaSessionDeleted = 'ShiftAreaSession_Deleted',
    ShiftAreaSessionUpdated = 'ShiftAreaSession_Updated',
    ShiftConflictCreated = 'ShiftConflict_Created',
    ShiftConflictDeleted = 'ShiftConflict_Deleted',
    ShiftConflictUpdated = 'ShiftConflict_Updated',
    ShiftSwapCreated = 'ShiftSwap_Created',
    ShiftSwapDeleted = 'ShiftSwap_Deleted',
    ShiftSwapUpdated = 'ShiftSwap_Updated',
    ShiftTagCreated = 'ShiftTag_Created',
    ShiftTagDeleted = 'ShiftTag_Deleted',
    ShiftTagUpdated = 'ShiftTag_Updated',
    ShiftTemplateCreated = 'ShiftTemplate_Created',
    ShiftTemplateDeleted = 'ShiftTemplate_Deleted',
    ShiftTemplateUpdated = 'ShiftTemplate_Updated',
    ShiftTemplateBreakCreated = 'ShiftTemplateBreak_Created',
    ShiftTemplateBreakDeleted = 'ShiftTemplateBreak_Deleted',
    ShiftTemplateBreakUpdated = 'ShiftTemplateBreak_Updated',
    ShiftTemplatesAreaSessionCreated = 'ShiftTemplatesAreaSession_Created',
    ShiftTemplatesAreaSessionDeleted = 'ShiftTemplatesAreaSession_Deleted',
    ShiftTemplatesAreaSessionUpdated = 'ShiftTemplatesAreaSession_Updated',
    ShiftTemplatesShiftAreaSessionCreated = 'ShiftTemplatesShiftAreaSession_Created',
    ShiftTemplatesShiftAreaSessionDeleted = 'ShiftTemplatesShiftAreaSession_Deleted',
    ShiftTemplatesShiftAreaSessionUpdated = 'ShiftTemplatesShiftAreaSession_Updated',
    ShiftTemplatesTagCreated = 'ShiftTemplatesTag_Created',
    ShiftTemplatesTagDeleted = 'ShiftTemplatesTag_Deleted',
    ShiftTemplatesTagUpdated = 'ShiftTemplatesTag_Updated',
    TagCreated = 'Tag_Created',
    TagDeleted = 'Tag_Deleted',
    TagUpdated = 'Tag_Updated',
    TimesheetBreakCreated = 'TimesheetBreak_Created',
    TimesheetBreakDeleted = 'TimesheetBreak_Deleted',
    TimesheetBreakUpdated = 'TimesheetBreak_Updated',
    TimesheetEntryCreated = 'TimesheetEntry_Created',
    TimesheetEntryDeleted = 'TimesheetEntry_Deleted',
    TimesheetEntryUpdated = 'TimesheetEntry_Updated',
    TimesheetEntryExportCreated = 'TimesheetEntryExport_Created',
    TimesheetEntryExportDeleted = 'TimesheetEntryExport_Deleted',
    TimesheetEntryExportUpdated = 'TimesheetEntryExport_Updated',
    TimesheetEntrySummaryCreated = 'TimesheetEntrySummary_Created',
    TimesheetEntrySummaryDeleted = 'TimesheetEntrySummary_Deleted',
    TimesheetEntrySummaryUpdated = 'TimesheetEntrySummary_Updated',
    TimesheetEntryTagCreated = 'TimesheetEntryTag_Created',
    TimesheetEntryTagDeleted = 'TimesheetEntryTag_Deleted',
    TimesheetEntryTagUpdated = 'TimesheetEntryTag_Updated',
    TrekksoftClientCredentialCreated = 'TrekksoftClientCredential_Created',
    TrekksoftClientCredentialDeleted = 'TrekksoftClientCredential_Deleted',
    TrekksoftClientCredentialUpdated = 'TrekksoftClientCredential_Updated',
    UnavailabilityCreated = 'Unavailability_Created',
    UnavailabilityDeleted = 'Unavailability_Deleted',
    UnavailabilityUpdated = 'Unavailability_Updated',
    UploadCreated = 'Upload_Created',
    UploadDeleted = 'Upload_Deleted',
    UploadUpdated = 'Upload_Updated',
    UserCreated = 'User_Created',
    UserDeleted = 'User_Deleted',
    UserUpdated = 'User_Updated',
    WorkPatternCreated = 'WorkPattern_Created',
    WorkPatternDeleted = 'WorkPattern_Deleted',
    WorkPatternUpdated = 'WorkPattern_Updated'
}


/**
 * Check if a given object implements the WebhookSubscription interface.
 */
export function instanceOfWebhookSubscription(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('targetUrl' in value)) return false;
    if (!('events' in value)) return false;
    if (!('publicKey' in value)) return false;
    if (!('errorReportingEmail' in value)) return false;
    return true;
}

export function WebhookSubscriptionFromJSON(json: any): WebhookSubscription {
    return WebhookSubscriptionFromJSONTyped(json, false);
}

export function WebhookSubscriptionFromJSONTyped(json: any, ignoreDiscriminator: boolean): WebhookSubscription {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'targetUrl': json['targetUrl'],
        'events': json['events'],
        'publicKey': json['publicKey'],
        'errorReportingEmail': json['errorReportingEmail'],
    };
}

export function WebhookSubscriptionToJSON(value?: WebhookSubscription | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'targetUrl': value['targetUrl'],
        'events': value['events'],
        'publicKey': value['publicKey'],
        'errorReportingEmail': value['errorReportingEmail'],
    };
}

