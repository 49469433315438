/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ShiftArea } from './ShiftArea';
import {
    ShiftAreaFromJSON,
    ShiftAreaFromJSONTyped,
    ShiftAreaToJSON,
} from './ShiftArea';

/**
 * 
 * @export
 * @interface CreateShiftAreaResponse
 */
export interface CreateShiftAreaResponse {
    /**
     * 
     * @type {ShiftArea}
     * @memberof CreateShiftAreaResponse
     */
    data?: ShiftArea;
}

/**
 * Check if a given object implements the CreateShiftAreaResponse interface.
 */
export function instanceOfCreateShiftAreaResponse(value: object): boolean {
    return true;
}

export function CreateShiftAreaResponseFromJSON(json: any): CreateShiftAreaResponse {
    return CreateShiftAreaResponseFromJSONTyped(json, false);
}

export function CreateShiftAreaResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateShiftAreaResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': json['data'] == null ? undefined : ShiftAreaFromJSON(json['data']),
    };
}

export function CreateShiftAreaResponseToJSON(value?: CreateShiftAreaResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'data': ShiftAreaToJSON(value['data']),
    };
}

