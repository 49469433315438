/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ShiftiePlainDate } from './ShiftiePlainDate';
import {
    ShiftiePlainDateFromJSON,
    ShiftiePlainDateFromJSONTyped,
    ShiftiePlainDateToJSON,
} from './ShiftiePlainDate';

/**
 * 
 * @export
 * @interface LeaveAllowanceChunk
 */
export interface LeaveAllowanceChunk {
    /**
     * 
     * @type {ShiftiePlainDate}
     * @memberof LeaveAllowanceChunk
     */
    date: ShiftiePlainDate;
    /**
     * 
     * @type {number}
     * @memberof LeaveAllowanceChunk
     */
    allowanceUsed: number;
    /**
     * 
     * @type {boolean}
     * @memberof LeaveAllowanceChunk
     */
    allowanceAffected: boolean;
    /**
     * 
     * @type {string}
     * @memberof LeaveAllowanceChunk
     */
    allowanceUnit?: LeaveAllowanceChunkAllowanceUnitEnum;
    /**
     * 
     * @type {boolean}
     * @memberof LeaveAllowanceChunk
     */
    paid: boolean;
}

/**
* @export
* @enum {string}
*/
export enum LeaveAllowanceChunkAllowanceUnitEnum {
    Days = 'Days',
    Hours = 'Hours'
}


/**
 * Check if a given object implements the LeaveAllowanceChunk interface.
 */
export function instanceOfLeaveAllowanceChunk(value: object): boolean {
    if (!('date' in value)) return false;
    if (!('allowanceUsed' in value)) return false;
    if (!('allowanceAffected' in value)) return false;
    if (!('paid' in value)) return false;
    return true;
}

export function LeaveAllowanceChunkFromJSON(json: any): LeaveAllowanceChunk {
    return LeaveAllowanceChunkFromJSONTyped(json, false);
}

export function LeaveAllowanceChunkFromJSONTyped(json: any, ignoreDiscriminator: boolean): LeaveAllowanceChunk {
    if (json == null) {
        return json;
    }
    return {
        
        'date': ShiftiePlainDateFromJSON(json['date']),
        'allowanceUsed': json['allowanceUsed'],
        'allowanceAffected': json['allowanceAffected'],
        'allowanceUnit': json['allowanceUnit'] == null ? undefined : json['allowanceUnit'],
        'paid': json['paid'],
    };
}

export function LeaveAllowanceChunkToJSON(value?: LeaveAllowanceChunk | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'date': ShiftiePlainDateToJSON(value['date']),
        'allowanceUsed': value['allowanceUsed'],
        'allowanceAffected': value['allowanceAffected'],
        'allowanceUnit': value['allowanceUnit'],
        'paid': value['paid'],
    };
}

