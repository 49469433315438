/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ShiftArea } from './ShiftArea';
import {
    ShiftAreaFromJSON,
    ShiftAreaFromJSONTyped,
    ShiftAreaToJSON,
} from './ShiftArea';

/**
 * 
 * @export
 * @interface ShowShiftAreaResponse
 */
export interface ShowShiftAreaResponse {
    /**
     * 
     * @type {ShiftArea}
     * @memberof ShowShiftAreaResponse
     */
    data?: ShiftArea;
}

/**
 * Check if a given object implements the ShowShiftAreaResponse interface.
 */
export function instanceOfShowShiftAreaResponse(value: object): boolean {
    return true;
}

export function ShowShiftAreaResponseFromJSON(json: any): ShowShiftAreaResponse {
    return ShowShiftAreaResponseFromJSONTyped(json, false);
}

export function ShowShiftAreaResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShowShiftAreaResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': json['data'] == null ? undefined : ShiftAreaFromJSON(json['data']),
    };
}

export function ShowShiftAreaResponseToJSON(value?: ShowShiftAreaResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'data': ShiftAreaToJSON(value['data']),
    };
}

