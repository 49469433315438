import i18n from '@/i18n';
import { Icon } from '@/lib/enum/Icon';
import {
  MenuItem,
  MenuSection,
  toItemMap,
  toSectionMap,
} from '@/lib/menus/helpers';
import { companyHasFeatureFlag } from '@/lib/permission/companyAccessFeatures';
import { userCan } from '@/lib/permission/userCan';
import { routes } from '@/router/routes';
import { isRouteActive } from '@/util/routeFunctions';
import {
  BillingDetails,
  BillingPlan,
  BillingSubscription,
  Company,
  CompanyFeatureFlagsEnum,
} from '../../../api/v1';

const settingsItem = toItemMap({
  general: (): MenuItem => ({
    name: i18n.t('nav.settings.company'),
    route: routes.generalSettings.route(),
    hide: false,
  }),
  locations: (): MenuItem => ({
    name: i18n.t('nav.settings.locations'),
    route: routes.locationSettings.route(),
    hide: false,
  }),
  workPatterns: (): MenuItem => ({
    name: i18n.t('nav.settings.workPatterns'),
    route: routes.manageWorkPatterns.route(),
    hide: false,
  }),
  notifications: (smsEnabled: boolean): MenuItem => ({
    name: i18n.t('nav.settings.notifications'),
    route: routes.notificationSettings.route(),
    hide: !smsEnabled && !userCan.manageTimesheetSettings(),
  }),
  schedule: (): MenuItem => ({
    name: i18n.t('nav.settings.schedule'),
    route: routes.scheduleSettings.route(),
    hide: !userCan.manageSchedules(),
  }),
  schedules: (): MenuItem => ({
    name: i18n.t('nav.settings.schedules'),
    route: routes.manageSchedules.route(),
    hide: !userCan.manageSchedules(),
  }),
  shiftTemplates: (): MenuItem => ({
    name: i18n.t('nav.settings.shiftTemplates'),
    route: routes.manageShiftTemplates.route(),
    hide: !userCan.manageShifts(),
  }),
  shiftAreas: (company: Company): MenuItem => ({
    name: i18n.t('nav.settings.shiftAreas'),
    route: routes.manageShiftAreasSettings.route(),
    hide:
      !userCan.manageShifts() ||
      !companyHasFeatureFlag(company, CompanyFeatureFlagsEnum.ShiftAreas),
  }),
  breaks: (): MenuItem => ({
    name: i18n.t('nav.settings.breaks'),
    route: routes.breakSettings.route(),
    hide: !userCan.manageSchedules(),
  }),
  scheduleEvents: (): MenuItem => ({
    name: i18n.t('nav.settings.scheduleEvents'),
    route: routes.manageScheduleEvents.route(),
    hide: !userCan.manageSchedules(),
  }),
  shiftTags: (): MenuItem => ({
    name: i18n.t('nav.settings.shiftTags'),
    route: routes.manageTags.route(),
    hide: !userCan.manageShifts(),
  }),
  jobRoles: (): MenuItem => ({
    name: i18n.t('nav.settings.jobRoles'),
    route: routes.manageJobRoles.route(),
    hide: false,
  }),
  employeeGroups: (): MenuItem => ({
    name: i18n.t('nav.settings.groups'),
    route: routes.manageEmployeeGroups.route(),
    hide: false,
  }),
  employeeAttributes: (): MenuItem => ({
    name: i18n.tc('entities.employeeAttribute', 0),
    route: routes.manageEmployeeAttributes.route(),
    hide: false,
  }),
  leaveSettings: (): MenuItem => ({
    name: i18n.t('nav.settings.leaveSettings'),
    route: routes.leaveSettings.route(),
    hide: !userCan.manageLeaveSettings(),
  }),
  leaveTypeSettings: (): MenuItem => ({
    name: i18n.t('nav.settings.leaveTypes'),
    route: routes.leaveTypeSettings.route(),
    hide: !userCan.manageLeaveSettings(),
  }),
  leavePolicies: (): MenuItem => ({
    name: i18n.t('nav.settings.leavePolicies'),
    route: routes.leavePolicySettings.route(),
    hide: !userCan.manageLeaveSettings(),
  }),
  timesheets: (): MenuItem => ({
    name: i18n.t('nav.timesheets'),
    route: routes.timesheetSettings.route(),
    hide: !userCan.manageTimesheetSettings(),
  }),
  clockInPortals: (): MenuItem => ({
    name: i18n.t('nav.settings.clockInPortals'),
    route: routes.manageClockInPortals.route(),
    hide: !userCan.manageClockInPortals(),
  }),
  digitickets: (): MenuItem => ({
    name: i18n.t('nav.settings.digitickets'),
    route: routes.digiticketsSettings.route(),
    hide: false,
  }),
  trekksoft: (): MenuItem => ({
    name: i18n.t('nav.settings.trekksoft'),
    route: routes.trekksoftSettings.route(),
    hide: false,
  }),
  account: (): MenuItem => ({
    name: i18n.t('nav.settings.account'),
    route: routes.accountSettings.route(),
    hide: false,
  }),
  billing: (
    billingSubscription: BillingSubscription,
    billingDetails: BillingDetails | null,
  ): MenuItem => ({
    name: i18n.t('nav.settings.billingDetails'),
    route: routes.billingSettings.route(),
    hide:
      !billingDetails ||
      (billingSubscription.price === 0 &&
        !billingSubscription.additionalEmployeePrice &&
        !billingSubscription.billingPlan.allowsCreditRenewals),
  }),
  invoices: (
    billingSubscription: BillingSubscription,
    isTrialVersion: boolean,
  ): MenuItem => ({
    name: i18n.t('nav.settings.invoices'),
    route: routes.invoices.route(),
    hide:
      isTrialVersion ||
      (billingSubscription.price === 0 &&
        !billingSubscription.additionalEmployeePrice &&
        !billingSubscription.billingPlan.allowsCreditRenewals),
  }),
  offers: (billingPlan: BillingPlan): MenuItem => ({
    name: i18n.t('nav.settings.offers'),
    route: routes.offers.route(),
    hide: !billingPlan.allowsOffers,
  }),
});

export const settingsMenu = toSectionMap({
  general: (smsEnabled): MenuSection => ({
    name: i18n.t('nav.settings.generalSettings'),
    icon: Icon.Gear,
    open: (routeName: string) =>
      settingsMenu
        .general(smsEnabled)
        .children.some((child): boolean => child.route.name === routeName),
    hide: !userCan.manageSettings(),
    children: [
      settingsItem.general(),
      settingsItem.workPatterns(),
      settingsItem.notifications(smsEnabled),
    ],
  }),
  scheduling: (company: Company): MenuSection => ({
    name: i18n.t('nav.settings.scheduling'),
    icon: Icon.CalendarDays,
    open: (routeName: string) =>
      settingsMenu
        .scheduling(company)
        .children.some((child): boolean =>
          isRouteActive(routeName, child.route.name),
        ),
    hide: !(userCan.manageShifts() || userCan.manageSchedules()),
    children: [
      settingsItem.schedule(),
      settingsItem.schedules(),
      settingsItem.shiftTemplates(),
      settingsItem.shiftAreas(company),
      settingsItem.breaks(),
      settingsItem.scheduleEvents(),
      settingsItem.shiftTags(),
    ],
  }),
  team: (): MenuSection => ({
    name: i18n.t('nav.settings.team'),
    icon: Icon.Users,
    open: (routeName: string) =>
      settingsMenu
        .team()
        .children.some((child): boolean => child.route.name === routeName),
    hide: !userCan.manageTeamSettings(),
    children: [
      settingsItem.jobRoles(),
      settingsItem.employeeGroups(),
      settingsItem.employeeAttributes(),
    ],
  }),
  timeOff: (): MenuSection => ({
    name: i18n.tc('nav.settings.timeOff'),
    icon: Icon.Plane,
    open: (routeName: string) =>
      settingsMenu
        .timeOff()
        .children.some((child): boolean =>
          isRouteActive(routeName, child.route.name),
        ),
    hide: !userCan.manageLeaveSettings(),
    children: [
      settingsItem.leaveSettings(),
      settingsItem.leaveTypeSettings(),
      settingsItem.leavePolicies(),
    ],
  }),
  timeAndAttendance: (): MenuSection => ({
    name: i18n.t('nav.settings.timeAndAttendance'),
    icon: Icon.Clock,
    open: (routeName: string) =>
      settingsMenu
        .timeAndAttendance()
        .children.some((child): boolean =>
          isRouteActive(routeName, child.route.name),
        ),
    hide: !userCan.manageSettings(),
    children: [
      settingsItem.locations(),
      settingsItem.clockInPortals(),
      settingsItem.timesheets(),
    ],
  }),
  integrations: (): MenuSection => ({
    name: i18n.t('nav.settings.integrations'),
    icon: Icon.ChartNetwork,
    open: (routeName: string) =>
      settingsMenu
        .integrations()
        .children.some((child): boolean => child.route.name === routeName),
    hide: !userCan.manageIntegrationSettings(),
    children: [settingsItem.digitickets(), settingsItem.trekksoft()],
  }),
  billing: (
    currentSubscription: BillingSubscription,
    isTrialVersion: boolean,
    billingDetails: BillingDetails | null,
  ): MenuSection => ({
    name: i18n.t('nav.settings.billingDetails'),
    icon: Icon.CreditCard,
    open: (routeName: string) =>
      settingsMenu
        .billing(currentSubscription, isTrialVersion, billingDetails)
        .children.some((child): boolean => child.route.name === routeName),
    hide: !userCan.manageBilling(),
    children: [
      settingsItem.account(),
      settingsItem.billing(currentSubscription, billingDetails),
      settingsItem.invoices(currentSubscription, isTrialVersion),
      settingsItem.offers(currentSubscription.billingPlan),
    ],
  }),
});
