/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Employee Pay Information
 * @export
 * @interface EmployeePayDetails
 */
export interface EmployeePayDetails {
    /**
     * The ID of the Employee Pay this relates to
     * @type {number}
     * @memberof EmployeePayDetails
     */
    id: number;
    /**
     * The ID of the Company this relates to
     * @type {number}
     * @memberof EmployeePayDetails
     */
    companyId?: number;
    /**
     * The ID of the Employee this relates to
     * @type {number}
     * @memberof EmployeePayDetails
     */
    employeeId?: number;
    /**
     * 
     * @type {number}
     * @memberof EmployeePayDetails
     */
    baseRate: number;
    /**
     * 
     * @type {string}
     * @memberof EmployeePayDetails
     */
    payrollId: string | null;
    /**
     * 
     * @type {Date}
     * @memberof EmployeePayDetails
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof EmployeePayDetails
     */
    updatedAt?: Date;
}

/**
 * Check if a given object implements the EmployeePayDetails interface.
 */
export function instanceOfEmployeePayDetails(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('baseRate' in value)) return false;
    if (!('payrollId' in value)) return false;
    return true;
}

export function EmployeePayDetailsFromJSON(json: any): EmployeePayDetails {
    return EmployeePayDetailsFromJSONTyped(json, false);
}

export function EmployeePayDetailsFromJSONTyped(json: any, ignoreDiscriminator: boolean): EmployeePayDetails {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'companyId': json['companyId'] == null ? undefined : json['companyId'],
        'employeeId': json['employeeId'] == null ? undefined : json['employeeId'],
        'baseRate': json['baseRate'],
        'payrollId': json['payrollId'],
        'createdAt': json['createdAt'] == null ? undefined : (new Date(json['createdAt'])),
        'updatedAt': json['updatedAt'] == null ? undefined : (new Date(json['updatedAt'])),
    };
}

export function EmployeePayDetailsToJSON(value?: EmployeePayDetails | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'companyId': value['companyId'],
        'employeeId': value['employeeId'],
        'baseRate': value['baseRate'],
        'payrollId': value['payrollId'],
        'createdAt': value['createdAt'] == null ? undefined : ((value['createdAt']).toISOString()),
        'updatedAt': value['updatedAt'] == null ? undefined : ((value['updatedAt'] as any).toISOString()),
    };
}

