<template>
  <div
    class="all:bg-white lg:bg-inherit all:p-0 all:pl-4 all:pb-4 lg:px-6 lg:pt-16"
  >
    <SidebarNav :menu="menu" />
  </div>
</template>

<script>
import SidebarNav from '@/components/nav/SidebarNav.vue';
import { Icon } from '@/lib/enum/Icon';
import { StorageEnum } from '@/lib/enum/StorageEnum';
import { userCan } from '@/lib/permission/userCan';
import { settingsMenu } from '@/lib/settings/settingsMenu';
import { Entity } from '@/lib/store/realtimeEntities';
import store from '@/store';
import { fetchEntity } from '@/stores/entities';
import { setLocalStorageItem } from '@/util/storageFunctions';

export default {
  name: 'SettingsMenu',

  components: { SidebarNav },

  beforeRouteEnter(to, from, next) {
    setLocalStorageItem(StorageEnum.FromRoute, from.name);
    next();
  },

  data() {
    return {
      Icon,
      featuresEnabled: fetchEntity(Entity.FeaturesEnabled),
      billingDetails: null,
    };
  },

  computed: {
    currentSubscription: () => store.state.billing.currentSubscription,
    isTrialVersion: () => store.getters['billing/isTrialVersion'],
    currentCompany: () => store.getters.currentCompany,

    menu() {
      return [
        settingsMenu.general(this.featuresEnabled.data?.smsEnabled || false),
        settingsMenu.scheduling(this.currentCompany),
        settingsMenu.team(),
        settingsMenu.timeOff(),
        settingsMenu.timeAndAttendance(),
        settingsMenu.integrations(),
        settingsMenu.billing(
          this.currentSubscription,
          this.isTrialVersion,
          this.billingDetails,
        ),
      ];
    },
  },

  async mounted() {
    if (userCan.manageBilling()) {
      const billingDetailsQuery = fetchEntity(Entity.BillingDetail);
      await billingDetailsQuery.promise;
      this.billingDetails = billingDetailsQuery.data;
    }
  },
};
</script>
