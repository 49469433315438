/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ShiftArea } from './ShiftArea';
import {
    ShiftAreaFromJSON,
    ShiftAreaFromJSONTyped,
    ShiftAreaToJSON,
} from './ShiftArea';

/**
 * 
 * @export
 * @interface UpdateShiftAreaResponse
 */
export interface UpdateShiftAreaResponse {
    /**
     * 
     * @type {ShiftArea}
     * @memberof UpdateShiftAreaResponse
     */
    data?: ShiftArea;
}

/**
 * Check if a given object implements the UpdateShiftAreaResponse interface.
 */
export function instanceOfUpdateShiftAreaResponse(value: object): boolean {
    return true;
}

export function UpdateShiftAreaResponseFromJSON(json: any): UpdateShiftAreaResponse {
    return UpdateShiftAreaResponseFromJSONTyped(json, false);
}

export function UpdateShiftAreaResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateShiftAreaResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': json['data'] == null ? undefined : ShiftAreaFromJSON(json['data']),
    };
}

export function UpdateShiftAreaResponseToJSON(value?: UpdateShiftAreaResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'data': ShiftAreaToJSON(value['data']),
    };
}

