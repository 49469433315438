// It's okay to have multiple classes in one file, as only one is exported
// eslint-disable-next-line max-classes-per-file
import { PlainDate } from '@/lib/date-time/PlainDate';
import Qs from 'qs';
import { fetchAbort } from './fetchAbort';
import {
  addVersionHeaders,
  authenticate,
  displayErrorsAsToasts,
  includeCredentials,
  recordApiVersion,
  redirectIfUnauthenticated,
} from './middleware';
import {
  ApiVersion,
  ApiVersionClassMap,
  ApiVersionTypeMap,
  middlewareCreator,
} from './types';

/**
 * Types specific for the factory classes, not shared in types.ts.
 */
type ApiCreator<
  A extends ApiVersion,
  T extends ApiVersionTypeMap[A]['BaseApi'],
> = new (c: ApiVersionTypeMap[A]['Configuration']) => T;

type ConfigurationCreator<A extends ApiVersion> = new (
  c: ApiVersionTypeMap[A]['ConfigurationParameters'],
) => ApiVersionTypeMap[A]['Configuration'];

type ApiFactoryConfig<A extends ApiVersion> = {
  displayErrorsAsToasts: boolean;
  redirectIfUnauthenticated: boolean;
  middleware: ApiVersionTypeMap[A]['Middleware'][];
};
type ApiFactoryConfigPartial<A extends ApiVersion> = Partial<
  ApiFactoryConfig<A>
>;

/**
 * Generic functions used for creating configurations
 */
const apiUrl = (v: string): string =>
  `https://api.${process.env.VUE_APP_BASE_URL}/${v}`;

const queryString =
  <A extends ApiVersion>() =>
  (params: ApiVersionTypeMap[A]['HttpQuery']): string => {
    /**
     * Serialise any of our custom objects.
     *
     * The generated SDK should really be handling this, but it doesn't and this
     * is much quicker than figuring out what PR we'd need to submit to the
     * generator package!
     */
    Object.keys(params).forEach((k) => {
      if (params[k] instanceof PlainDate) {
        params[k] = params[k].toString();
      }
    });

    return Qs.stringify(params, {
      arrayFormat: 'brackets',
      encodeValuesOnly: true,
      allowEmptyArrays: true,
    });
  };

const getDefaultApiFactoryConfig = <
  A extends ApiVersion,
>(): ApiFactoryConfig<A> => {
  return {
    displayErrorsAsToasts: true,
    redirectIfUnauthenticated: true,
    middleware: [
      authenticate<A>(),
      includeCredentials<A>(),
      addVersionHeaders<A>(),
      recordApiVersion<A>(),
    ],
  };
};

class ApiEntityCache<A extends ApiVersion> {
  private store: Record<string, ApiVersionTypeMap[A]['BaseApi']> = {};

  // It doesn't use `this` directly but use its generic type
  // eslint-disable-next-line class-methods-use-this
  private key<T extends ApiVersionTypeMap[A]['BaseApi']>(
    creator: ApiCreator<A, T>,
    c: ApiFactoryConfig<A>,
  ): string {
    return `${creator.name}_${btoa(JSON.stringify(c))}`;
  }

  public get<T extends ApiVersionTypeMap[A]['BaseApi']>(
    creator: ApiCreator<A, T>,
    c: ApiFactoryConfig<A>,
  ): T | null {
    return (this.store[this.key(creator, c)] ?? null) as T;
  }

  public put<T extends ApiVersionTypeMap[A]['BaseApi']>(
    creator: ApiCreator<A, T>,
    c: ApiFactoryConfig<A>,
    i: T,
  ): T {
    this.store[this.key(creator, c)] = i;

    return i;
  }
}

class ApiConfigurationFactory<A extends ApiVersion> {
  private customConfigurationParams: Record<
    string,
    ApiVersionTypeMap[A]['ConfigurationParameters']
  > = {};

  public constructor(private type: A) {}

  public create<T extends ApiVersionTypeMap[A]['BaseApi']>(
    api: ApiCreator<A, T>,
    cp: ApiFactoryConfigPartial<A>,
  ): ApiVersionTypeMap[A]['Configuration'] {
    return this.createRaw<T>(
      api,
      ApiVersionClassMap[this.type].Configuration,
      cp,
    );
  }

  public registerCustomDefaultConfiguration<
    T extends ApiVersionTypeMap[A]['BaseApi'],
  >(
    api: ApiCreator<A, T>,
    c: ApiVersionTypeMap[A]['ConfigurationParameters'],
  ): void {
    this.registerCustomDefaultConfigurationRaw(api, c);
  }

  public registerCustomDefaultConfigurationRaw<
    T extends ApiVersionTypeMap[A]['BaseApi'],
  >(
    api: ApiCreator<A, T>,
    c: ApiVersionTypeMap[A]['ConfigurationParameters'],
  ): void {
    this.customConfigurationParams[api.name] = c;
  }

  public makeConfigurationParameters(
    c: Partial<ApiVersionTypeMap[A]['ConfigurationParameters']>,
  ): ApiVersionTypeMap[A]['ConfigurationParameters'] {
    return {
      basePath: apiUrl(this.type),
      queryParamsStringify: queryString<A>(),
      fetchApi: fetchAbort,
      middleware: [],
      ...c,
    };
  }

  private createRaw<T extends ApiVersionTypeMap[A]['BaseApi']>(
    api: ApiCreator<A, T>,
    Creator: ConfigurationCreator<A>,
    cp: ApiFactoryConfigPartial<A>,
  ): ApiVersionTypeMap[A]['Configuration'] {
    const dp = getDefaultApiFactoryConfig();

    const customConfig = this.customConfigurationParams[api.name];
    const params = {
      ...customConfig,
      middleware: customConfig ? [...customConfig.middleware] : dp.middleware,
    };

    if (cp.redirectIfUnauthenticated || dp.redirectIfUnauthenticated) {
      params.middleware.push(redirectIfUnauthenticated<A>());
    }

    if (cp.displayErrorsAsToasts || dp.displayErrorsAsToasts) {
      params.middleware.push(displayErrorsAsToasts<A>());
    }

    return new Creator(this.makeConfigurationParameters(params));
  }
}

export class ApiFactory<A extends ApiVersion> {
  private store = new ApiEntityCache<A>();

  private configurationFactory: ApiConfigurationFactory<A>;

  public constructor(private type: A) {
    this.configurationFactory = new ApiConfigurationFactory(this.type);
  }

  public prepare<T extends ApiVersionTypeMap[A]['BaseApi']>(
    Creator: ApiCreator<A, T>,
  ): (cp?: ApiFactoryConfigPartial<A>) => T {
    return (cp: ApiFactoryConfigPartial<A> = {}): T => this.create(Creator, cp);
  }

  public create<T extends ApiVersionTypeMap[A]['BaseApi']>(
    Creator: ApiCreator<A, T>,
    cp: ApiFactoryConfigPartial<A> = {},
  ): T {
    const config = { ...getDefaultApiFactoryConfig<A>(), ...cp };
    const existing = this.store.get(Creator, config);

    if (existing !== null) {
      return existing;
    }

    const instance = new Creator(this.configurationFactory.create(Creator, cp));

    return this.store.put(Creator, config, instance);
  }

  // It doesn't use `this` directly but use its generic type
  // eslint-disable-next-line class-methods-use-this
  public createMiddleware(
    m: middlewareCreator,
  ): ApiVersionTypeMap[A]['Middleware'] {
    return m<A>();
  }

  public registerCustomDefaultConfiguration<
    T extends ApiVersionTypeMap[A]['BaseApi'],
  >(
    api: ApiCreator<A, T>,
    c: ApiVersionTypeMap[A]['ConfigurationParameters'],
  ): void {
    this.configurationFactory.registerCustomDefaultConfiguration(api, c);
  }

  public makeConfigurationParameters(
    c: Partial<ApiVersionTypeMap[A]['ConfigurationParameters']>,
  ): ApiVersionTypeMap[A]['ConfigurationParameters'] {
    return this.configurationFactory.makeConfigurationParameters(c);
  }
}

export const authApiFactory = new ApiFactory(ApiVersion.Auth);
export const v1ApiFactory = new ApiFactory(ApiVersion.V1);
